import { connect } from 'react-redux';
import Component from './PageOrganizationSetting';
import { getOrganizations } from '../../store/app/selectors';
import { fetchUser } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
    organizations: getOrganizations(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUser: () => dispatch(fetchUser()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

