import React from 'react';

export default function PageNotFound({ authorize, isAuthorized }) {


  return (
    <>
      <div style={{ height: '100%', minHeight: '900px', width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>

        not found

      </div>
    </>
  );
}
