import React, { useState } from 'react';
import { Grid } from '@mui/material';

export default function ModalEnvironmentCreate({
  createEnvironment,
  handleClose,
  projectId,
}) {

  console.log('pprojectId', projectId);

  const [environmentName, setEnvironmentName] = useState('');
  const [environmentHost, setEnvironmentHost] = useState('');

  const handleEnvironmentName = (e) => {
    setEnvironmentName(e.target.value);
  };

  const handleEnvironmentHost = (e) => {
    setEnvironmentHost(e.target.value);
  };

  const handleCreateEnvironment = () => {
    const environment = {
      project: projectId,
      name: environmentName,
      host: environmentHost,
    };

    createEnvironment(environment, handleClose);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <h3 style={{ fontSize: '18px' }}>
            Create new environment
          </h3>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right', paddingTop: '16px' }}>
          <i
            style={{
              cursor: 'pointer',
              padding: '6px 10px 8px 8px',
              borderRadius: '15px',
              background: 'linear-gradient(146.06deg, rgb(61, 70, 95) 0%, rgb(25, 31, 54) 100%)'
            }}
            onClick={handleClose}
          >🗙</i>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11}>
          <input
            type={'text'}
            name={'environment.name'}
            placeholder={'Name'}
            value={environmentName}
            onChange={handleEnvironmentName}
          />
          <input
            type={'text'}
            name={'environment.description'}
            placeholder={'Description'}
            value={environmentHost}
            onChange={handleEnvironmentHost}
          />
        </Grid>
      </Grid>

      <button onClick={handleCreateEnvironment}>
        Create
      </button>
    </>
  );
}
