import { connect } from 'react-redux';
import Component from './ResourcesList';
import { setCurrentResource } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentResource: (resource) => dispatch(setCurrentResource(resource)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

