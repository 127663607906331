import React from 'react';

export default function ResultStatus ({ status }) {
  if (status === 'success') {
    return <span title={'Test success'} style={{ color: 'green', fontSize: '1.4em' }}>✓</span>;
  }

  return <span title={'Test failed'} style={{ color: 'red', fontSize: '1.4em' }}>×</span>;
};

