import { connect } from 'react-redux';
import Component from './App';
import { getProjects, isAuthorized } from '../../store/app/selectors';
import { fetchProjects, setAuthorization } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
    projects: getProjects(state),
    isAuthorized: isAuthorized(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchProjects: () => dispatch(fetchProjects()),
    setAuthorization: (token) => dispatch(setAuthorization(token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

