import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import RouterHelper from '../../helpers/RouterHelper';
import Link from '../../common/Link';
import ButtonPlus from '../../assets/icons/button_plus.svg';
import ModalProjectCreate from '../../components/ModalProjectCreate';
import modalHook from '../../hook/useModal';
import Tile from '../../common/Tile';
import Top from '../../components/Top';
import { useParams } from 'react-router-dom';

export default function PageProjectsList({ projects, resetResources, fetchProjects }) {

  const { organization } = useParams();

  useEffect(() => {
    if (!projects) {
      fetchProjects();
    }
    resetResources();
  }, [projects]);

  const [ModalProjectCreateComponent, handleOpenModalProjectCreate] = modalHook(ModalProjectCreate, { organizationSlug: organization});

  if (!projects) {
    return <div>Loading</div>;
  }

  return (
    <>
      <Top/>
      <Grid container style={{ padding: '16px' }}>
        {projects.map(function(project, index) {
          return (
            <Grid item xs={3} key={index} data-id={project.id} style={{  }}>
              <Tile styles={{ 'padding':'0', }}>
                <div style={{ padding: '32px', height: '40px' }}>
                  <span style={{
                    'width':'6px',
                    'height':'6px',
                    'borderRadius':'6px',
                    'backgroundColor':'#cdff05',
                    'float':'left',
                    'marginTop':'10px',
                    'marginRight':'10px',
                  }}/>

                  <span style={{
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '25px',
                    color: '#fff',
                    marginBottom: '32px'
                  }}>
                    <Link
                      to={RouterHelper.generate('route_project_editor', {'organization':organization,'projectId':project.id})}
                      style={{ color: '#ffffff',  }}
                    >
                      {project.name}
                    </Link>
                  </span>
                  {/*<Grid container>*/}
                  {/*  <Grid item>*/}
                  {/*    <img src={Chart}/>*/}
                  {/*  </Grid>*/}
                  {/*</Grid>*/}
                </div>
                <Grid container style={{ borderTop: '1px solid rgba(61, 70, 95, 0.2)', textAlign: 'center' }}>
                  <Grid item xs={6} style={{ padding: '16px 32px', borderRight: '1px solid rgba(61, 70, 95, 0.2)' }}>
                    <Link
                      to={RouterHelper.generate('route_project_editor', {'projectId':project.id})}
                      style={{ color: '#5F6986', fontWeight: 600 }}
                    >
                      Resources
                    </Link>
                  </Grid>
                  <Grid item xs={6} style={{ padding: '16px 32px' }}>
                    <Link
                      to={RouterHelper.generate('route_test_suites', {'projectId':project.id})}
                      style={{ color: '#5F6986', fontWeight: 600 }}
                    >
                      Test cases
                    </Link>
                  </Grid>
                </Grid>
              </Tile>
            </Grid>
          );
        })}
        <img
          src={ButtonPlus}
          alt={'Create new project'}
          style={{ position: 'fixed', bottom: '24px', right: '48px'}}
          onClick={handleOpenModalProjectCreate}
          className={'cursor'}
        />
        {ModalProjectCreateComponent}
      </Grid>
    </>
  );
}
