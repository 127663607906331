import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { HttpCodes } from '../../../helpers/HttpHelper';

export default function ResponseForm({
  response,
}) {

  const [responseState, setResponseState] = useState(response);
  const handleInputChange = (event) => {
    response[event.target.name] = event.target.value;
    setResponseState({...response});
  }

  return (
    <Grid container>
      <Grid item style={{'width':'600px'}}>
        <label>
          <select
            name="content.responses.200.statusCode"
            onChange={handleInputChange}
            value={responseState.statusCode}
          >
            {HttpCodes.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </label>
        <label>
          <input
            name="content.responses.200.description"
            // onChange={handleInputChange}
            value={responseState.description}
          >
          </input>
        </label>
        {/*{schema.path}*/}
        {/*<br/>*/}
        {/*{schema.method}*/}
        {/*<br/>*/}
        {/*{schema.description}*/}
        {/*<br/>*/}
        {/*{JSON.stringify(schema.content || "{}")}*/}
      </Grid>
      {/*<Grid item style={{'width':'600px'}}>*/}
      {/*  <form onSubmit={formHandler}>*/}
      {/*    <label>*/}
      {/*      <select*/}
      {/*        name="method"*/}
      {/*        onChange={handleInputChange}*/}
      {/*        value={schema.method}*/}
      {/*      >*/}
      {/*        {methods.map((option, index) => (*/}
      {/*          <option key={index} value={option.value}>*/}
      {/*            {option.label}*/}
      {/*          </option>*/}
      {/*        ))}*/}
      {/*      </select>*/}
      {/*    </label>*/}
      {/*  </form>*/}
      {/*</Grid>*/}
    </Grid>
  );
}
