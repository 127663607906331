import React from 'react';
import { HttpMethodsColors } from '../../helpers/HttpHelper';

export default function Method ({ method }) {

  method = method.toLowerCase();
  const color = HttpMethodsColors[method];

  return (
    <span style={{ textTransform: 'uppercase', color: color, fontSize: '0.8em' }}>
      {method}
    </span>
  );
};

