import { connect } from 'react-redux';
import Component from './EnvironmentVariablesForm';
import { updateEnvironment } from '../../../store/app/actions';

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateEnvironment: ({ environment, successCallback }) => dispatch(updateEnvironment({ environment, successCallback })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
