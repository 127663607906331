import { connect } from 'react-redux';
import Component from './SchemaEditor';
import { updateSchema } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateSchema: (schema) => dispatch(updateSchema(schema)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

