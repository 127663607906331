import { AppStateInterface } from './interfaces';

const initialAppState: AppStateInterface = {
  projects: undefined,
  resources: undefined,
  schemas: undefined,
  environments: [],
  currentResource: undefined,
  currentSchema: undefined,
  organizations: undefined,
  config: {
    apiHost: process.env.REACT_APP_API_BASE_URL,
    apiToken: undefined,
  },
};

export default initialAppState;
