import React, { useState } from 'react';
import { Grid } from '@mui/material';
import ButtonPlus from '../../assets/icons/button_plus.svg';

export default function SchemaProperty({ dotpathPrefix, name, type, required, properties, items, onSchemaUpdate }) {

  const [propName, setPropName] = useState(name || undefined);
  const [propType, setPropType] = useState(type || 'string');

  dotpathPrefix = dotpathPrefix || '';

  const onNameChange = (e) => {
    setPropName(e.target.value.replace(/\s/g, ""));
  }

  const handleNameChange = (e) => {
    let dot = dotpathPrefix.substr(0, dotpathPrefix.lastIndexOf("."))
    onSchemaUpdate([{
      dotpath: `${dot}.${name}`,
      value: undefined,
      dotpathToReplace: `${dot}.${e.target.value}`
    }]);
  }

  const onTypeChange = (e) => {
    const type = e.target.value;
    setPropType(type);

    let dotpath = dotpathPrefix ? `.${dotpathPrefix}` : '';
    dotpath += `.type`;

    let dotpaths = [
      {
        dotpath: `${dotpathPrefix}.items`,
        value: undefined,
      },
      {
        dotpath: `${dotpathPrefix}.properties`,
        value: undefined,
      },
      {
        dotpath: dotpath,
        value: type,
      },
    ];

    if (type === 'object') {
      dotpaths.push({
        dotpath: `${dotpathPrefix}.properties`,
        value: {},
      });
    }

    onSchemaUpdate(dotpaths);
  }

  const onRequiredChange = (e) => {
    const value = e.target.value;

    if (e.target.checked) {
      required = required || [];
      if (!required.includes(value)) {
        required.push(value);
      }
    }
    else if (required && required.includes(value)) {
      required = required.filter(e => e !== value);
    }

    if (required.length === 0) {
      required = [];
    }

    onSchemaUpdate([
      {
        dotpath: `${dotpathPrefix}.required`,
        value: required,
      }
    ]);
  }

  const onAddProperty = () => {
    let tmp = JSON.parse(JSON.stringify(properties));
    let n, i = 1;

    do {
      n = 'name' + (++i);
      if (i >= 1000) {
        return;
      }
    }
    while (tmp[n] && typeof tmp[n] !== 'undefined')

    tmp[n] = {
      type: 'string',
    }

    let dotpath = dotpathPrefix || '';
    dotpath += `.properties.${n}`;

    onSchemaUpdate([{
      dotpath: dotpath,
      value: tmp[n],
    }]);
  }

  const onRemoveProperty = (e) => {
    const n = e.currentTarget.dataset['name'];
    let tmp = JSON.parse(JSON.stringify(properties));
    delete tmp[n];
    // setPropProperties(tmp);
    console.log('onRemoveProperty', `${dotpathPrefix}.properties.${n}`);
    onSchemaUpdate([{ dotpath: `${dotpathPrefix}.properties.${n}`, value: undefined }]);
  }

  // console.log(propName, propType, propRequired, propProperties, Object.keys(propProperties));

  // const TypeArray = ({ items }) => {
  //   return (
  //     <ul>
  //       {Object.keys(items.properties).map((name, index) => {
  //         return <li key={index}>
  //           <SchemaProperty name={name} type={items.properties[name].type}/>
  //         </li>
  //       })}
  //       <li>a</li>
  //     </ul>
  //   );
  // }

  const SelectType = ({ value, onTypeChange }) => {
    return (
      <select value={value} onChange={onTypeChange}>
        <option value={'string'}>string</option>
        <option value={'integer'}>integer</option>
        <option value={'object'}>object</option>
        <option value={'array'}>array</option>
        <option value={'$ref'}>$ref</option>
      </select>
    );
  }

  // if (propType === 'object') {
  //   dotpathPrefix += '.properties';
  // }

  return (
    <>
      <Grid container>
        <Grid item style={{'width':'600px'}}>
          <Grid container>
            <Grid item xs={5}>
              {propName !== undefined ? <input type={'text'} value={propName} onChange={onNameChange} onBlur={handleNameChange} placeholder={'Name'}/> : null}
            </Grid>
            <Grid item xs={4}>
              <SelectType value={propType} onTypeChange={onTypeChange}/>
            </Grid>
          </Grid>
          <br/>
          {type === 'object' && properties ? <ul>
            {Object.keys(properties).map((n, index) => {
              const property = properties[n];
              console.log(required, n, required && required.indexOf(n), required && required.indexOf(n) >= 0);
              return <li key={`${name}-${n}-${index}`}>
                <input type={'checkbox'} value={n} checked={(required && required.indexOf(n) >= 0)} onChange={onRequiredChange}/>
                <SchemaProperty
                  dotpathPrefix={`${dotpathPrefix}.properties.${n}`}
                  name={n}
                  type={property.type}
                  required={property.required || undefined}
                  properties={property.properties || undefined}
                  onSchemaUpdate={onSchemaUpdate}
                />
                <span onClick={onRemoveProperty} data-name={n}>
                  ✘
                </span>
              </li>
            })}
            <li>
              <img onClick={onAddProperty} src={ButtonPlus} alt={'Add property'} style={{width: '30px'}}/>
            </li>
          </ul> : null}
          {/*{propType === 'array' ? <TypeArray items={propItems}/> : null}*/}
        </Grid>
      </Grid>
    </>
  );
}
