import { connect } from 'react-redux';
import Component from './PageHome';
import { isAuthorized } from '../../store/app/selectors';

const mapStateToProps = (state) => {
  return {
    isAuthorized: isAuthorized(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

