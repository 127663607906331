import {createAction} from '@reduxjs/toolkit';
import {
  CreateAccount,
  Environment,
  EnvironmentCreate,
  Organization,
  Project,
  Resource,
  Schema,
  StoreInitActionPayload,
  UpdateResourceInterface,
  UserAuthorization,
} from './interfaces';

export const storeInit = createAction<StoreInitActionPayload>('STORE_INIT');
export const authorize = createAction<UserAuthorization>('AUTHORIZE');
export const createAccount = createAction<CreateAccount>('CREATE_ACCOUNT');
export const setAuthorization = createAction<string|undefined>('SET_AUTHORIZATION');

export const fetchProjects = createAction('FETCH_PROJECTS');
export const setProjects = createAction<Project[]>('SET_PROJECTS');
export const createProject = createAction<object>('CREATE_PROJECT');
export const addProject = createAction<Project>('ADD_PROJECT');

export const fetchResources = createAction('FETCH_RESOURCES');
export const setResources = createAction<Resource[]>('SET_RESOURCES');

export const setCurrentResource = createAction<Resource>('SET_CURRENT_RESOURCE');
export const getCurrentResource = createAction('GET_CURRENT_RESOURCE');

export const createSchema = createAction<object>('CREATE_SCHEMA');
export const createResource = createAction<object>('CREATE_RESOURCE');

export const updateResourceResponse = createAction<Project>('UPDATE_RESOURCE_RESPONSE');

export const fetchSchemas = createAction<string>('FETCH_SCHEMAS');
export const setSchemas = createAction<Schema[]>('SET_SCHEMAS');
export const addSchema = createAction<Schema>('ADD_SCHEMAS');
export const setCurrentSchema = createAction<Schema>('SET_CURRENT_SCHEMA');

export const updateSchema = createAction<Schema>('UPDATE_SCHEMA');
export const setSchemaById = createAction<Schema>('SET_SCHEMA_BY_ID');

export const updateResource = createAction<UpdateResourceInterface>('UPDATE_RESOURCE');
export const persistResource = createAction('PERSIST_RESOURCE');

export const fetchEnvironments = createAction<string>('FETCH_ENVIRONMENTS');
export const setEnvironments = createAction<Environment[]>('SET_ENVIRONMENTS');
export const createEnvironment = createAction<EnvironmentCreate>('CREATE_ENVIRONMENT');
export const updateEnvironment = createAction<EnvironmentCreate>('UPDATE_ENVIRONMENT');
export const setEnvironmentById = createAction<Environment>('SET_ENVIRONMENT_BY_ID');


export const fetchUser = createAction('FETCH_USER');
export const setOrganizations = createAction<Organization[]>('SET_ORGANIZATIONS');
