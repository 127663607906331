import React from 'react';

import ButtonPlus from '../../assets/icons/button_plus.svg';
import Method from '../../common/Method';
import useModal from '../../hook/useModal';
import ModalResourceCreate from '../../components/ModalResourceCreate';

export default function ResourcesList({
  resources,
  setCurrentResource,
  projectId,
}) {
  const [Modal, handleOpenModal] = useModal(ModalResourceCreate, {projectId});

  if (!resources) {
    return (
      <>Loading</>
    );
  }

  let folders = resources
    .map(resource => {
      return resource.folder || null;
    })
    // remove duplicates
    .filter((folder, index, self) =>
        folder !== null && index === self.findIndex((t) => (
          t.id === folder.id
        ))
    )
    .sort((folder1, folder2) => {
      return folder1.position > folder2.position ? 1 : -1;
    })
  ;
  console.log('folders', folders);

  let sortedResources = [...resources];

  sortedResources
    .sort((r1, r2) => {
      if (r1.path === r2.path) {
        return 0;
      }

      return r1.path > r2.path ? 1 : -1;
    });

  return (
    <>
      <h4>
        Resources <img
        src={ButtonPlus}
        onClick={handleOpenModal}
        style={{ cursor: 'pointer', width: '40px', position: 'relative', top: '18px' }}
        alt={'Add new resource'}
      />
      </h4>
      {folders.map((folder, index) => {
        return (
          <div key={index}>
            {folder.name} ({folder.position})
            <div style={{'paddingLeft':'16px'}}>
              {sortedResources.map(function(resource, index2) {
                if (typeof resource.folder !== "object" || resource.folder.id !== folder.id) {
                  return;
                }

                return (
                  <div key={index2} onClick={() => setCurrentResource(resource)} style={{ cursor: 'pointer' }}>
                    <Method method={resource.method}/>
                    {resource.path}
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      {sortedResources.map(function(resource, index) {
        if (typeof resource.folder !== "object") {
          return (
            <div key={index} onClick={() => setCurrentResource(resource)} style={{ cursor: 'pointer' }}>
              <Method method={resource.method}/>
              {resource.path}
            </div>
          );
        }

        return null;
      })}
      {Modal}
    </>
  );
}
