import { createReducer } from '@reduxjs/toolkit';
import {
    addTestCase,
    addTestCaseStep,
    removeTestCaseStep,
    setTestCaseResults,
    setTestCases,
} from './actions';
import initialAppState from './defaultState';
import {
    TestCase,
    TestCaseCreate,
    TestCaseResult,
    TestCaseStepRemove,
    TestCaseStepUpdate,
} from './interfaces';

export default createReducer(initialAppState, (builder) => {
  builder
    .addCase(setTestCases, (draft, { payload }: { payload: TestCase[] }) => {
        payload.forEach((testCase) => {
            draft.testCases = {...draft.testCases, [testCase.id]: testCase };
        });
    })
    .addCase(setTestCaseResults, (draft, { payload }: { payload: TestCaseResult[] }) => {
        payload.forEach((testCaseResult) => {
            draft.testCaseResults = {...draft.testCaseResults, [testCaseResult.id]: testCaseResult };
        });
    })
    .addCase(addTestCase, (draft, { payload }: { payload: TestCaseCreate }) => {
        draft.testCases[payload.testCase.id] = payload.testCase;
    })
    .addCase(addTestCaseStep, (draft, { payload }: { payload: TestCaseStepUpdate }) => {
        const testCaseId = payload.testCaseId;
        const testCaseStep = payload.testCaseStep;

        let testCase = {...draft.testCases[testCaseId]};

        testCase['testCaseSteps'].forEach((tcs, index) => {
            if (tcs.position >= testCaseStep.position) {
                testCase['testCaseSteps'][index].position++;
            }
        });

        testCase['testCaseSteps'].push(testCaseStep);
        console.log(testCaseStep);

        draft.testCases[testCaseId] = testCase;
    })
    .addCase(removeTestCaseStep, (draft, { payload }: { payload: TestCaseStepRemove }) => {
        const testCase = draft.testCases[payload.testCaseId];

        const testCaseStepIndex = testCase['testCaseSteps'].findIndex((testCaseStep) => {
            return testCaseStep.id === payload.testCaseStepId;
        });

        console.log('testCaseStepIndex', testCaseStepIndex);

        draft.testCases[payload.testCaseId]['testCaseSteps'].splice(testCaseStepIndex, 1);
    })
  ;
});
