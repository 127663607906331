import { connect } from 'react-redux';
import Component from './ModalTestCaseCreate';
import { createTestCase } from '../../store/testCase/actions';

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    createTestCase: (projectId, testCase, successCallback) => dispatch(createTestCase({ projectId, testCase, successCallback })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

