import { connect } from 'react-redux';
import Component from './ResourceEditor';
import { persistResource, updateResource } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateResource: (resourceId, dotpath, value) => dispatch(updateResource({id: resourceId, dotpath, value})),
    persistResource: () => dispatch(persistResource()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

