import { connect } from 'react-redux';
import Component from './PageTestCaseEditor';
import { getTestCaseById } from '../../store/testCase/selectors';
import { createTestCaseStep, fetchTestCase, removeTestCaseStep } from '../../store/testCase/actions';
import { getResources } from '../../store/app/selectors';
import { fetchResources } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
    getTestCase: (testCaseId) => getTestCaseById(state, testCaseId),
    resources: getResources(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTestCase: (testCaseId) => dispatch(fetchTestCase(testCaseId)),
    fetchResources: () => dispatch(fetchResources()),
    createTestCaseStep: (projectId, testCaseId, testCaseStep) => dispatch(createTestCaseStep({projectId, testCaseId, testCaseStep})),
    removeTestCaseStep: ({projectId, testCaseId, testCaseStepId}) => dispatch(removeTestCaseStep({projectId, testCaseId, testCaseStepId}))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
