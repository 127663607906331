import {
  addTestCase,
  addTestCaseStep,
  createTestCase,
  createTestCaseStep,
  fetchTestCase,
  fetchTestCaseResult,
  fetchTestCaseResults,
  fetchTestCases,
  removeTestCaseStep,
  setTestCaseResults,
  setTestCases,
  setTestCaseStep,
  updateTestCase,
  updateTestCaseStep,
} from './actions';
import { select, put, takeEvery } from 'redux-saga/effects';
import { getApiHost, getCurrentResource } from '../../store/app/selectors';
import { defaultHeaders } from '../../api/api';
import { type } from '@testing-library/user-event/dist/type';


function * handleFetchTestCasesByProjectId(action) {
  const projectId = action.payload;
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders()};

  let testCases = yield fetch(`${apiHost}/api/projects/${projectId}/test-cases`, {
      headers: head,
      method: 'GET',
    })
      .then(response => response.json())
  ;

  console.log(`Fetch suite tests for ${projectId}`, testCases);

  yield put(setTestCases(testCases));
}

function * handleFetchTestCaseById(action) {
  const testCaseId = action.payload;
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders()};

  let testCase = yield fetch(`${apiHost}/api/test-cases/${testCaseId}`, {
      headers: head,
      method: 'GET',
    })
      .then(response => response.json())
  ;

  console.log(`Fetch single test case for ${testCaseId}`, testCase);

  yield put(setTestCases([testCase]));
}

function * handleUpdateTestCase(action) {
  const state = yield select();
  const apiHost = getApiHost(state);
  const {projectId, testCaseId, testCase} = action.payload;

  const head = { ...yield defaultHeaders(), ...{'Content-Type':'application/merge-patch+json'} };

  let updatedTestCase = yield fetch(`${apiHost}/api/test-cases/${testCaseId}`, {
      headers: head,
      method: 'PATCH',
      body: JSON.stringify(testCase),
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
  ;

  console.log(`Updated testCase`, updatedTestCase);

  yield put(setTestCases([updatedTestCase]));
}

function * handleRemoveTestCaseStep(action) {
  const state = yield select();
  const apiHost = getApiHost(state);
  const {testCaseStepId} = action.payload;

  yield fetch(`${apiHost}/api/test-case-steps/${testCaseStepId}`, {
      headers: yield defaultHeaders(),
      method: 'DELETE',
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return true;
    })
  ;

  console.log(`Deleted testCaseStep`, testCaseStepId);
}

function * handleCreateTestCaseStep(action) {
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders() };

  let testCaseStep = yield fetch(`${apiHost}/api/test-case-steps`, {
      headers: head,
      method: 'POST',
      body: JSON.stringify(action.payload.testCaseStep),
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
  ;

  console.log(`Create testCaseStep`, testCaseStep);
console.log(action.payload);
  yield put(addTestCaseStep({projectId: action.payload.projectId, testCaseId: action.payload.testCaseId, testCaseStep}));
}

function * handleCreateTestCase(action) {
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders() };

  let testCase = yield fetch(`${apiHost}/api/test-cases`, {
      headers: head,
      method: 'POST',
      body: JSON.stringify(action.payload.testCase),
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
  ;

  console.log(`Create testCase`, testCase);

  if (typeof action.payload.successCallback === 'function') {
    action.payload.successCallback();
  }

console.log(action.payload);
  yield put(addTestCase({
    projectId: action.payload.projectId,
    testCase: testCase,
    successCallback: undefined,
  }));
}

function * handleUpdateTestCaseStep(action) {
  const state = yield select();
  const apiHost = getApiHost(state);
  const {projectId, testCaseId, testCaseStep} = action.payload;

  const head = { ...yield defaultHeaders(), ...{'Content-Type':'application/merge-patch+json'} };

  const tmp = {...testCaseStep};

  if (typeof tmp.resource === 'object') {
    tmp.resource = tmp.resource.id;
  }

  let updatedTestCaseStep = yield fetch(`${apiHost}/api/test-case-steps/${testCaseStep.id}`, {
      headers: head,
      method: 'PATCH',
      body: JSON.stringify(tmp),
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
  ;

  console.log(`Updated testCaseStep`, updatedTestCaseStep);

  yield put(setTestCaseStep({projectId, testCaseId, testCaseStep: updatedTestCaseStep}));
}

function * handleFetchTestCaseResultsByTestCaseId(action) {
  const testCaseId = action.payload;
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders()};

  let testCaseResults = yield fetch(`${apiHost}/api/test-cases/${testCaseId}/test-case-results`, {
      headers: head,
      method: 'GET',
    })
      .then(response => response.json())
  ;

  console.log(`Fetch results for testCaseId ${testCaseId}`, testCaseResults);

  yield put(setTestCaseResults(testCaseResults));
}

function * handleFetchTestCaseResultByTestCaseResultId(action) {
  const testCaseResultId = action.payload;
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders()};

  let testCaseResult = yield fetch(`${apiHost}/api/test-case-results/${testCaseResultId}`, {
      headers: head,
      method: 'GET',
    })
      .then(response => response.json())
  ;

  console.log(`Fetch test case result for testCaseResultId ${testCaseResultId}`, testCaseResult);

  yield put(setTestCaseResults([testCaseResult]));
}

export default [
  takeEvery(String(fetchTestCases), handleFetchTestCasesByProjectId),
  takeEvery(String(fetchTestCase), handleFetchTestCaseById),
  takeEvery(String(updateTestCase), handleUpdateTestCase),
  takeEvery(String(removeTestCaseStep), handleRemoveTestCaseStep),
  takeEvery(String(createTestCaseStep), handleCreateTestCaseStep),
  takeEvery(String(updateTestCaseStep), handleUpdateTestCaseStep),
  takeEvery(String(createTestCase), handleCreateTestCase),
  takeEvery(String(fetchTestCaseResults), handleFetchTestCaseResultsByTestCaseId),
  takeEvery(String(fetchTestCaseResult), handleFetchTestCaseResultByTestCaseResultId),
];
