import { connect } from 'react-redux';
import Component from './ModalSchemaCreate';
import { createSchema } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    createSchema: (schema, successCallback) => dispatch(createSchema({ schema, successCallback })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

