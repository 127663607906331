import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { HttpMethods } from '../../helpers/HttpHelper';

export default function ModalResourceCreate({
  createResource,
  handleClose,
  projectId,
}) {

  const [resourceName, setResourceName] = useState('');
  const [resourcePath, setResourcePath] = useState('');
  const [resourceMethod, setResourceMethod] = useState('get');

  const handleResourceName = (e) => {
    setResourceName(e.target.value);
  };

  const handleResourcePath = (e) => {
    setResourcePath(e.target.value);
  };

  const handleResourceMethod = (e) => {
    setResourceMethod(e.target.value);
  };

  const handleCreateResource = () => {
    const resource = {
      name: resourceName,
      path: resourcePath,
      method: resourceMethod,
      project: projectId,
    };

    createResource(resource, handleClose);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <h3 style={{ fontSize: '18px' }}>
            Create new resource
          </h3>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right', paddingTop: '16px' }}>
          <i
            style={{
              cursor: 'pointer',
              padding: '6px 10px 8px 8px',
              borderRadius: '15px',
              background: 'linear-gradient(146.06deg, rgb(61, 70, 95) 0%, rgb(25, 31, 54) 100%)'
            }}
            onClick={handleClose}
          >🗙</i>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11}>
          <input
            autoComplete={'off'}
            type={'text'}
            name={'resource.name'}
            placeholder={'Name'}
            value={resourceName}
            onChange={handleResourceName}
          />
          <input
            autoComplete={'off'}
            type={'text'}
            name={'resource.path'}
            placeholder={'Path'}
            value={resourcePath}
            onChange={handleResourcePath}
          />
          <select
            name="method"
            onChange={handleResourceMethod}
            value={resourceMethod}
          >
            {HttpMethods.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </Grid>
      </Grid>

      <button onClick={handleCreateResource}>
        Create
      </button>
    </>
  );
}
