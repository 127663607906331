import React, { Fragment, useState } from 'react';
import { Grid } from '@mui/material';
import { HttpCodes } from '../../../helpers/HttpHelper';
import ButtonPlus from '../../../assets/icons/button_plus.svg';

export default function TestCaseStepForm({
  projectId,
  testCaseId,
  resources,
  testCaseStep,
  onRemoveTestCaseStep,
  updateTestCaseStep,
}) {

  const [step, setStep] = useState(testCaseStep);
  let emptyBody = JSON.stringify(testCaseStep.body) === '[]' ? {} : testCaseStep.body;
  const [body, setBody] = useState(JSON.stringify(emptyBody, null, 2));
  const [bodyError, setBodyError] = useState(false);

  const onHeaderChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(step));
    tmp.headers[e.target.dataset['index']][e.target.name] = e.target.value;
    setStep(tmp);
  }

  const handleHeaderChange = () => {
    updateTestCaseStep(
      projectId,
      testCaseId,
      step,
    );
  }

  const handleAddHeader = () => {
    let tmp = JSON.parse(JSON.stringify(step));
    tmp.headers.push({
      name: '',
      value: '',
    });
    setStep(tmp);
    updateTestCaseStep(
      projectId,
      testCaseId,
      tmp,
    );
  }

  const handleRemoveHeader = (e) => {
    let tmp = JSON.parse(JSON.stringify(step));
    tmp.headers.splice(e.target.dataset['index'], 1);
    setStep(tmp);
    updateTestCaseStep(
      projectId,
      testCaseId,
      tmp,
    );
  }

  const onInitialVariableChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(step));
    tmp.initialVariables[e.target.dataset['index']][e.target.name] = e.target.value;
    setStep(tmp);
  }

  const handleInitialVariableChange = () => {
    updateTestCaseStep(
      projectId,
      testCaseId,
      step,
    );
  }

  const handleAddInitialVariable = () => {
    let tmp = JSON.parse(JSON.stringify(step));
    tmp.initialVariables.push({
      name: '',
      value: '',
    });
    setStep(tmp);
    updateTestCaseStep(
      projectId,
      testCaseId,
      tmp,
    );
  }

  const handleRemoveInitialVariable = (e) => {
    let tmp = JSON.parse(JSON.stringify(step));
    tmp.initialVariables.splice(e.target.dataset['index'], 1);
    setStep(tmp);
    updateTestCaseStep(
      projectId,
      testCaseId,
      tmp,
    );
  }

  const onBodyChange = (e) => {
    try {
      JSON.parse(e.target.value);
      setBody(e.target.value);
      setBodyError(false);
      console.log(1);
    }
    catch (exception) {
      setBodyError(true);
      setBody(e.target.value);
      console.log(2);
    }
  }

  const handleBodyChange = (e) => {
    if (bodyError) {
      return;
    }

    // let bodyString = e.target.value;

    try {
      let tmpBody = JSON.parse(e.target.value);
      let tmp = JSON.parse(JSON.stringify(step));
      tmp.body = tmpBody;
      setStep(tmp);
      updateTestCaseStep(
        projectId,
        testCaseId,
        tmp,
      );
      setBodyError(false);
    }
    catch (exception) {
      setBodyError(true);
      setBody(e.target.value);
    }
  }

  const handleExpectedResponseStatusCodeChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(step));
    tmp.expectedResponseStatusCode = parseInt(e.target.value);
    setStep(tmp);
    updateTestCaseStep(
      projectId,
      testCaseId,
      tmp,
    );
  }

  const handleResourceChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(step));
    tmp.resource = resources.find((resource) => {
      return resource.id === e.target.value;
    }).id;
    tmp.queryParams = [];
    setStep(tmp);
    updateTestCaseStep(
      projectId,
      testCaseId,
      tmp,
    );
  }

  const onExtractVariableChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(step));
    tmp.extractVariables[e.target.dataset['index']][e.target.name] = e.target.value;
    setStep(tmp);
  }

  const handleExtractVariableChange = () => {
    updateTestCaseStep(
      projectId,
      testCaseId,
      step,
    );
  }

  const handleAddExtractVariable = () => {
    let tmp = JSON.parse(JSON.stringify(step));
    tmp.extractVariables.push({
      name: '',
      value: '',
    });
    setStep(tmp);
    updateTestCaseStep(
      projectId,
      testCaseId,
      tmp,
    );
  }

  const handleRemoveExtractVariable = (e) => {
    let tmp = JSON.parse(JSON.stringify(step));
    tmp.extractVariables.splice(e.target.dataset['index'], 1);
    setStep(tmp);
    updateTestCaseStep(
      projectId,
      testCaseId,
      tmp,
    );
  }

  const onQueryParamChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(step));
    if (!tmp.queryParams.hasOwnProperty(e.target.dataset['index'])) {
      tmp.queryParams[e.target.dataset['index']] = {
        name: e.target.name,
        value: '',
      };
    }
    tmp.queryParams[e.target.dataset['index']]['value'] = e.target.value;
    setStep(tmp);
  }

  const handleTestCaseStepChange = () => {
    updateTestCaseStep(
      projectId,
      testCaseId,
      step,
    );
  }

  resources.sort((resource1, resource2) => {
    if (resource1.path === resource2.path) {
      return 0;
    }

    return resource1.path > resource2.path ? -1 : 1;
  })

  let resource = resources.find((resource) => {
    return resource.id === (step.resource.id ?? step.resource);
  });
  let path = resource ? resource.path : '';
  let queryParams = path.match(/{([a-zA-Z0-9]+)}/g);
  console.log('queryParams', queryParams);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={6}>
            #{step.position} - {step.id}
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <span onClick={(e) => onRemoveTestCaseStep(e, step)} className={'cursor'}>
              🗑
            </span>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <img
          src={ButtonPlus}
          alt={'Add new step'}
          style={{ width: '24px', lineHeight: '12px', verticalAlign: 'middle', marginRight: '8px' }}
          className={'cursor'}
          onClick={(e) => handleAddInitialVariable(e, step)}
        />
        <span>Initial variables:</span>
        {step.initialVariables.map((variable, index) => {
          return <Fragment key={index}>
            <Grid container>
              <Grid item xs={'auto'} style={{  padding: '28px 12px 0 7px' }}>
                <span data-index={index} className={'cursor'} onClick={handleRemoveInitialVariable}>
                  🗑
                </span>
              </Grid>
              <Grid item xs={5}>
                <input type={'text'} name={'name'} data-index={index} placeholder={'Name'} value={variable.name} onChange={onInitialVariableChange} onBlur={handleInitialVariableChange}/>
              </Grid>
              <Grid item xs={6}>
                <input type={'text'} name={'value'} data-index={index} placeholder={'value'} value={variable.value} onChange={onInitialVariableChange} onBlur={handleInitialVariableChange}/>
              </Grid>
            </Grid>
          </Fragment>;
        })}
        <br/>
        Endpoint:
        <select value={step.resource.id ?? step.resource} onChange={handleResourceChange}>
          {resources.map((resource, index) => {
            return <option value={resource.id} key={index}>
              {resource.path} {resource.method.toUpperCase()} {resource.name}
            </option>
          })}
        </select>
        <br/>
        <Grid container>
          {(queryParams ?? []).map((param, index) => {
            return <Fragment key={index}>
              <Grid item xs={6}>
                {param}
              </Grid>
              <Grid item xs={6}>
                <input type={'text'} value={(step.queryParams[index] ?? {value: ''}).value} name={param} data-index={index} onChange={onQueryParamChange} onBlur={handleTestCaseStepChange}/>
              </Grid>
            </Fragment>;
          })}
        </Grid>
        <br/>
        <img
          src={ButtonPlus}
          alt={'Add new step'}
          style={{ width: '24px', lineHeight: '12px', verticalAlign: 'middle', marginRight: '8px' }}
          className={'cursor'}
          onClick={(e) => handleAddHeader(e, step)}
        />
        <span>Headers:</span>
        {step.headers.map((header, index) => {
          return <Fragment key={index}>
            <Grid container>
              <Grid item xs={'auto'} style={{  padding: '28px 12px 0 7px' }}>
                <span data-index={index} className={'cursor'} onClick={handleRemoveHeader}>
                  🗑
                </span>
              </Grid>
              <Grid item xs={5}>
                <input type={'text'} name={'name'} data-index={index} placeholder={'Name'} value={header.name} onChange={onHeaderChange} onBlur={handleHeaderChange}/>
              </Grid>
              <Grid item xs={6}>
                <input type={'text'} name={'value'} data-index={index} placeholder={'value'} value={header.value} onChange={onHeaderChange} onBlur={handleHeaderChange}/>
              </Grid>
            </Grid>
          </Fragment>;
        })}
        <br/>
        Body:
        <textarea
          style={{ height: 'auto', minHeight: '160px', borderColor: bodyError ? '#f00' : '' }}
          value={body}
          onChange={onBodyChange}
          onBlur={handleBodyChange}
          // onInput={autoGrow}
        />
      </Grid>
      <Grid item xs={6}>
        Expected response status code:
        <select value={step.expectedResponseStatusCode} onChange={handleExpectedResponseStatusCodeChange}>
          {HttpCodes.map((status, index) => {
            return <option key={index} value={status.value}>
              {status.label} ({status.value})
            </option>;
          })}
        </select>

        <img
          src={ButtonPlus}
          alt={'Add new step'}
          style={{ width: '24px', lineHeight: '12px', verticalAlign: 'middle', marginRight: '8px' }}
          className={'cursor'}
          onClick={(e) => handleAddExtractVariable(e, step)}
        />
        Extract variables:
        {step.extractVariables.map((extractVariable, index) => {
          return <Fragment key={index}>
            <Grid container>
              <Grid item xs={'auto'} style={{  padding: '28px 12px 0 7px' }}>
                <span data-index={index} className={'cursor'} onClick={handleRemoveExtractVariable}>
                  🗑
                </span>
              </Grid>
              <Grid item xs={5}>
                <input type={'text'} name={'name'} data-index={index} placeholder={'Name'} value={extractVariable.name} onChange={onExtractVariableChange} onBlur={handleExtractVariableChange}/>
              </Grid>
              <Grid item xs={6}>
                <input type={'text'} name={'value'} data-index={index} placeholder={'Value'} value={extractVariable.value} onChange={onExtractVariableChange} onBlur={handleExtractVariableChange}/>
              </Grid>
            </Grid>
          </Fragment>;
        })}
      </Grid>
    </Grid>
  );
}
