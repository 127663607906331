import {
  addProject,
  addSchema,
  authorize, createAccount,
  createProject,
  createResource,
  createSchema,
  fetchEnvironments,
  fetchProjects,
  fetchResources,
  fetchSchemas,
  fetchUser,
  persistResource,
  setAuthorization,
  setEnvironmentById,
  setEnvironments,
  setOrganizations,
  setProjects,
  setResources,
  setSchemaById,
  setSchemas,
  updateEnvironment,
  updateSchema,
} from './actions';
import { select, put, takeEvery } from 'redux-saga/effects';
import { getApiHost, getCurrentResource } from '../../store/app/selectors';
import { defaultHeaders } from '../../api/api';

function * handleAuthorize(action) {
  const state = yield select();
  const apiHost = getApiHost(state);
  const head = { ...yield defaultHeaders()};

  const { response, error } = yield fetch(`${apiHost}/api/login`, {
      headers: head,
      method: 'POST',
      body: JSON.stringify(action.payload),
    })
      .then(response => ({ response }))
      .catch(error => ({ error }))
  ;

  if (response && response.status === 200) {
    let json = yield response.json();
    localStorage.setItem('token', json.token);
    yield put(setAuthorization(json.token));
  }
  else if (response) {
    switch (response.status) {
      case 401:
        console.log(401);
        break;
    }
  }
  else {
    console.log('error');
  }
}

function * handleCreateAccount(action) {
  const state = yield select();
  const apiHost = getApiHost(state);
  const head = { ...yield defaultHeaders()};

  const { response, error } = yield fetch(`${apiHost}/api/register`, {
      headers: head,
      method: 'POST',
      body: JSON.stringify(action.payload),
    })
      .then(response => ({ response }))
      .catch(error => ({ error }))
  ;

  if (response && response.status === 200) {
    let json = yield response.json();
    console.log(json);
    // localStorage.setItem('token', json.token);
    // yield put(setAuthorization(json.token));
  }
  else if (response) {
    switch (response.status) {
      case 401:
        console.log(401);
        break;
    }
  }
  else {
    console.log('error');
  }
}

function * handleFetchProjects() {
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders()};

  let projects = yield fetch(`${apiHost}/api/projects`, {
      headers: head,
      method: 'GET',
    })
      .then(response => response.json())
  ;

  yield put(setProjects(projects));
}

function * handleFetchSchemas(action) {
  const projectId = action.payload;
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders()};

  let schemas = yield fetch(`${apiHost}/api/projects/${projectId}/schemas`, {
      headers: head,
      method: 'GET',
    })
      .then(response => response.json())
  ;

  yield put(setSchemas(schemas));
}

function * handleFetchResources() {
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders()};

  let resources = yield fetch(`${apiHost}/api/resources`, {
      headers: head,
      method: 'GET',
    })
      .then(response => response.json())
  ;

  console.log(`Fetch resources`, resources);

  resources.map((resource) => {
    if (!resource.content) {
      return resource;
    }

    if (!resource.content.responses) {
      return resource;
    }

    let responses = [];

    for (const [statusCode, response] of Object.entries(resource.content?.response || {})) {
      response.statusCode = statusCode;
      responses.push(response);
    }

    resource.content.responses = responses;
  })

  yield put(setResources(resources));
}

function * handleFetchUser() {
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders()};

  let user = yield fetch(`${apiHost}/api/users/me`, {
      headers: head,
      method: 'GET',
    })
      .then(response => response.json())
  ;

  console.log(`Fetch user`, user);

  yield put(setOrganizations(user.organizations));
}

function * handleCreateProject(action) {
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders() };

  let project = yield fetch(`${apiHost}/api/projects`, {
      headers: head,
      method: 'POST',
      body: JSON.stringify(action.payload.project),
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
  ;

  console.log(`Create project`, project, typeof action.payload.project);

  if (typeof action.payload.successCallback !== 'undefined') {
    action.payload.successCallback();
  }

  yield put(addProject(project));
}

function * handleCreateSchema(action) {
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders() };

  let schema = yield fetch(`${apiHost}/api/schemas`, {
      headers: head,
      method: 'POST',
      body: JSON.stringify(action.payload.schema),
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
  ;

  console.log(`Create schema`, schema, typeof action.payload.schema);

  if (typeof action.payload.successCallback !== 'undefined') {
    action.payload.successCallback();
  }

  yield put(addSchema(schema));
}

function * handleCreateResource(action) {
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders() };

  let resource = yield fetch(`${apiHost}/api/resources`, {
      headers: head,
      method: 'POST',
      body: JSON.stringify(action.payload.resource),
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
  ;

  console.log(`Create resource`, resource, typeof action.payload.successCallback);

  if (typeof action.payload.successCallback === 'function') {
    action.payload.successCallback();
  }

  yield put(setResources([resource]));
}

function * handlePersistResource(action) {
  const state = yield select();
  const apiHost = getApiHost(state);
  const currentResource = {...getCurrentResource(state)};

  const head = { ...yield defaultHeaders(), ...{'Content-Type':'application/merge-patch+json'} };
  console.log(head);

  if (typeof currentResource.folder !== 'undefined') {
    delete currentResource.folder;
  }

  let resource = yield fetch(`${apiHost}/api/resources/${currentResource.id}`, {
      headers: head,
      method: 'PATCH',
      body: JSON.stringify(currentResource),
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
  ;

  console.log(`Updated resource`, resource);

  yield put(setResources([resource]));
}

function * handleUpdateSchema(action) {
  const state = yield select();
  const apiHost = getApiHost(state);
  const updateSchema = action.payload;

  const head = { ...yield defaultHeaders(), ...{'Content-Type':'application/merge-patch+json'} };

  let schema = yield fetch(`${apiHost}/api/schemas/${updateSchema.id}`, {
      headers: head,
      method: 'PATCH',
      body: JSON.stringify(updateSchema),
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
  ;

  console.log(`Updated schema`, schema);

  yield put(setSchemaById(schema));
}

function * handleFetchEnvironments(action) {
  const projectId = action.payload;
  const state = yield select();
  const apiHost = getApiHost(state);

  const head = { ...yield defaultHeaders()};

  let environments = yield fetch(`${apiHost}/api/projects/${projectId}/environments`, {
      headers: head,
      method: 'GET',
    })
      .then(response => response.json())
  ;

  console.log(`Environments`, environments);

  yield put(setEnvironments(environments));
}

function * handleUpdateEnvironment(action) {
  const state = yield select();
  const apiHost = getApiHost(state);
  const { environment } = action.payload;

  const head = { ...yield defaultHeaders(), ...{'Content-Type':'application/merge-patch+json'} };

  let updatedEnvironment = yield fetch(`${apiHost}/api/environments/${environment.id}`, {
      headers: head,
      method: 'PATCH',
      body: JSON.stringify(environment),
    })
    .then((response) => {
      if (!response.ok) {
        throw response;
      }
      return response.json();
    })
  ;

  console.log(`Updated environment`, updatedEnvironment);

  yield put(setEnvironmentById(updatedEnvironment));
}

export default [
  takeEvery(String(fetchProjects), handleFetchProjects),
  takeEvery(String(fetchSchemas), handleFetchSchemas),
  takeEvery(String(fetchResources), handleFetchResources),
  takeEvery(String(createProject), handleCreateProject),
  takeEvery(String(createResource), handleCreateResource),
  takeEvery(String(persistResource), handlePersistResource),
  takeEvery(String(updateSchema), handleUpdateSchema),
  takeEvery(String(createSchema), handleCreateSchema),
  takeEvery(String(fetchEnvironments), handleFetchEnvironments),
  takeEvery(String(authorize), handleAuthorize),
  takeEvery(String(createAccount), handleCreateAccount),
  takeEvery(String(updateEnvironment), handleUpdateEnvironment),
  takeEvery(String(fetchUser), handleFetchUser),
];
