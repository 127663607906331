import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Top from '../../components/Top';
import { useParams } from 'react-router-dom';
import EnvironmentForm from '../../pages/PageEnvironmentsEditor/EnvironmentForm';
import EnvironmentVariablesForm from '../../pages/PageEnvironmentsEditor/EnvironmentVariablesForm';

export default function PageEnvironmentsEditor({ environments, fetchEnvironments }) {

  const { projectId } = useParams();
  const { environmentId } = useParams();
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (!fetched){
      fetchEnvironments(projectId);
      setFetched(true);
    }
  }, [fetched]);

  console.log(environments, fetched);

  if (!environments || !fetched) {
    return <div>Loading1</div>;
  }

  const environment = environments.find((environment) => {
    return environment.id === environmentId;
  })
  console.log('environment', environment);
  if (!environment) {
    return <div>Loading2</div>;
  }

  console.log(environment);

  return (
    <>
      <Top/>
      <Grid container spacing={2} style={{ padding: '16px' }}>
        <Grid item xs={6}>
          <EnvironmentForm environment={environment}/>
        </Grid>
        <Grid item xs={6}>
          <EnvironmentVariablesForm environment={environment}/>
        </Grid>
      </Grid>
    </>
  );
}
