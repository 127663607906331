


export function getTestCases({ testCase }, projectId) {
  let testCases = [];

  Object.keys(testCase.testCases).forEach((key, index) => {
    if (testCase.testCases[key].project === projectId) {
      testCases.push(testCase.testCases[key]);
    }
  });

  return testCases;
}

export function getTestCaseResults({ testCase }, testCaseId) {
  let testCaseResults = [];

  Object.keys(testCase.testCaseResults).forEach((key, index) => {
    if (testCase.testCaseResults[key].testCase.id === testCaseId) {
      testCaseResults.push(testCase.testCaseResults[key]);
    }
  });

  return testCaseResults;
}

export function getTestCaseResult({ testCase }, testCaseResultId) {
  return testCase.testCaseResults[testCaseResultId] ?? undefined;
}

export function getTestCaseById({ testCase }, testCaseId) {
  if (!testCase.testCases[testCaseId]) {
    return undefined;
  }

  return testCase.testCases[testCaseId];
}
