import { connect } from 'react-redux';
import Component from './PageProjectEditor';
import { getApiToken, getCurrentResource, getCurrentSchema, getResources, getSchemas } from '../../store/app/selectors';
import { fetchResources, fetchSchemas } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
    apiToken: getApiToken(state),
    resources: getResources(state),
    schemas: getSchemas(state),
    currentResource: getCurrentResource(state),
    currentSchema: getCurrentSchema(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchResources: () => dispatch(fetchResources()),
    fetchSchemas: (projectId) => dispatch(fetchSchemas(projectId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

