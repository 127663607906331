import React, { useState } from 'react';
import { Grid } from '@mui/material';

export default function ModalTestCaseCreate({
  createTestCase,
  projectId,
  handleClose,
}) {

  const [testCaseName, setTestCaseName] = useState('');
  const [testCaseDescription, setTestCaseDescription] = useState('');

  const handleTestCaseName = (e) => {
    setTestCaseName(e.target.value);
  };

  const handleTestCaseDescription = (e) => {
    setTestCaseDescription(e.target.value);
  };

  const handleCreateTestCase = () => {
    const testCase = {
      name: testCaseName,
      description: testCaseDescription,
      project: projectId,
      // environment: "string",
    };

    createTestCase(projectId, testCase, handleClose);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <h3 style={{ fontSize: '18px' }}>
            Create new Test Case
          </h3>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right', paddingTop: '16px' }}>
          <i
            style={{
              cursor: 'pointer',
              padding: '6px 10px 8px 8px',
              borderRadius: '15px',
              background: 'linear-gradient(146.06deg, rgb(61, 70, 95) 0%, rgb(25, 31, 54) 100%)'
            }}
            onClick={handleClose}
          >🗙</i>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11}>
          <input
            type={'text'}
            placeholder={'Name'}
            value={testCaseName}
            onChange={handleTestCaseName}
          />
          <input
            type={'text'}
            placeholder={'Description'}
            value={testCaseDescription}
            onChange={handleTestCaseDescription}
          />
        </Grid>
      </Grid>

      <button onClick={handleCreateTestCase}>
        Create
      </button>
    </>
  );
}
