import { connect } from 'react-redux';
import Component from './TestCaseStepForm';
import { updateTestCaseStep } from '../../../store/testCase/actions';

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestCaseStep: (projectId, testCaseId, testCaseStep) => dispatch(updateTestCaseStep({projectId, testCaseId, testCaseStep})),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
