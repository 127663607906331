import { connect } from 'react-redux';
import Component from './ModalResourceCreate';
import { createResource } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    createResource: (resource, successCallback) => dispatch(createResource({ resource, successCallback })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

