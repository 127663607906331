import React, { useState } from 'react';

export default function EnvironmentForm({ environment, updateEnvironment }) {

  const [environmentState, setEnvironmentState] = useState(environment);

  const onChange = (e) => {
    e.preventDefault();
    const tmp = { ...environmentState, ...{[e.target.name]: e.target.value} };
    setEnvironmentState(tmp);
  }

  const handleChange = (e) => {
    e.preventDefault();
    updateEnvironment({ environment: environmentState, successCallback: undefined });
  }

  return (
    <>
      <span>Name</span>
      <input type={'text'} value={environmentState.name} name={'name'} onChange={onChange} onBlur={handleChange} autoComplete={'off'}/>
      <span>Host</span>
      <input type={'text'} value={environmentState.host} name={'host'} onChange={onChange} onBlur={handleChange} autoComplete={'off'}/>
    </>
  );
}
