import React from 'react';

export default function Tile ({ children, styles }) {

  styles = styles ?? {};

  return <div style={{...{
    background:'linear-gradient(118.86deg,#1f2840,#191f36)',
    borderRadius:'10px',
    boxShadow: '0px 15px 30px rgba(13, 16, 32, 0.3)',
    padding:'32px',
    color:'#fff',
    margin: '16px',
  }, ...styles}}>
    {children}
  </div>;
};

