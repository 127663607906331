import React, { useState } from 'react';
import { Grid } from '@mui/material';

export default function ModalProjectCreate({
  createProject,
  handleClose,
  organizationSlug,
}) {

  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');

  const handleProjectName = (e) => {
    setProjectName(e.target.value);
  };

  const handleProjectDescription = (e) => {
    setProjectDescription(e.target.value);
  };

  const handleCreateProject = () => {
    const project = {
      name: projectName,
      description: projectDescription,
    };

    createProject(project, handleClose);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <h3 style={{ fontSize: '18px' }}>
            Create new project
          </h3>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right', paddingTop: '16px' }}>
          <i
            style={{
              cursor: 'pointer',
              padding: '6px 10px 8px 8px',
              borderRadius: '15px',
              background: 'linear-gradient(146.06deg, rgb(61, 70, 95) 0%, rgb(25, 31, 54) 100%)'
            }}
            onClick={handleClose}
          >🗙</i>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11}>
          <input
            type={'text'}
            name={'project.name'}
            placeholder={'Name'}
            value={projectName}
            onChange={handleProjectName}
          />
          <input
            type={'text'}
            name={'project.description'}
            placeholder={'Description'}
            value={projectDescription}
            onChange={handleProjectDescription}
          />
        </Grid>
      </Grid>

      <button onClick={handleCreateProject}>
        Create
      </button>
    </>
  );
}
