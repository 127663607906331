import React, { Fragment } from 'react';
import { Navigate } from 'react-router-dom';
import RouterHelper from '../../helpers/RouterHelper';
import { Grid } from '@mui/material';
import LogoFull from '../../assets/icons/logo_full.svg';
import Rocket from '../../assets/shapes/rocket.svg';
import CodeIcon from '../../assets/icons/code.svg';
import PuzzleIcon from '../../assets/icons/puzzle.svg';
import Shape5 from '../../assets/shapes/shape5.svg';
import Shape6 from '../../assets/shapes/shape6.svg';
import Tile from '../../common/Tile';
import Link from '../../common/Link';

export default function PageHome({ isAuthorized }) {

  if (isAuthorized) {
    return <Navigate replace to={RouterHelper.generate('route_projects_list')} />
  }

  const features = [
    {
      icon: PuzzleIcon,
      name: 'Feature 1',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci tristique sem nam eu non. Tellus tincidunt at ornare euismod quam ac sed.',
    },
    {
      icon: PuzzleIcon,
      name: 'Feature 2',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci tristique sem nam eu non. Tellus tincidunt at ornare euismod quam ac sed.',
    },
    {
      icon: PuzzleIcon,
      name: 'Feature 3',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci tristique sem nam eu non. Tellus tincidunt at ornare euismod quam ac sed.',
    },
    {
      icon: PuzzleIcon,
      name: 'Feature 4',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Orci tristique sem nam eu non. Tellus tincidunt at ornare euismod quam ac sed.',
    },
  ];

  return (
    <div style={{ backgroundColor: '#0d1020' }}>
      <img src={Shape5} alt={'Mockresponse.io'} style={{ position: 'absolute', left: '0', top: '400px' }}/>
      <img src={Shape6} alt={'Mockresponse.io'} style={{ position: 'absolute', right: '0', top: '1000px', zIndex: 1 }}/>
      <Grid
        container
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      >
        <Grid item xs={4} style={{ padding: '60px' }}>
          <img src={LogoFull} alt={'Mockresponse.io'}/>
        </Grid>
        <Grid item xs={4}>

        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right', padding: '66px' }}>
          <a className={'btn'} href={RouterHelper.generate('route_login')}>login</a>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ textAlign: 'center', fontSize: '48px', fontWeight: 200, margin: '40px 0' }}>
          REST API Testing tool for <strong>Everyone</strong><span style={{ color: '#f92d3c', fontWeight: 600 }}>.</span>
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center', margin: '40px 0' }}>
          <a className={'btn'} href={RouterHelper.generate('route_register')}>
            try now for free
          </a>
        </Grid>
      </Grid>
      <Grid container maxWidth={'lg'} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Grid item xs={6} style={{ fontSize: '38px', padding: '80px' }}>
          New standard in
          <br/>
          <strong>API Development</strong>
        </Grid>
        <Grid item xs={3} style={{  }}>
          <Tile>
            <img src={CodeIcon} alt={'Web based client'}/>
            <h3>Web-based client</h3>
            <p>Cross platform, responsive and intuitive</p>
          </Tile>
        </Grid>
        <Grid item xs={3} style={{  }}>
          <Tile>
            <img src={PuzzleIcon} alt={'Scalable'}/>
            <h3>Scalable</h3>
            <p>Depend on your need, unlimited parallel workers</p>
          </Tile>
        </Grid>

        <Grid item xs={6}>
          <Tile styles={{ height: '180px', position: 'relative' }}>
            <img src={Rocket} alt={'Rocket'} style={{ position: 'absolute', top: '-80px', left: '-60px' }}/>
            <div style={{ marginLeft: '240px' }}>
              <h3>Get started now!</h3>
              <br/>
              <br/>
              <a className={'btn'} href={RouterHelper.generate('route_register')}>
                Try free
              </a>
            </div>
          </Tile>
        </Grid>
        <Grid item xs={3} style={{  }}>
          <Tile>
            <img src={PuzzleIcon} alt={'Scalable'}/>
            <h3>Build schema</h3>
            <p>Build open schema for your REST API</p>
          </Tile>
        </Grid>
        <Grid item xs={3} style={{  }}>
          <Tile>
            <img src={PuzzleIcon} alt={'Scalable'}/>
            <h3>Create test cases</h3>
            <p>Test your REST API from business point of view</p>
          </Tile>
        </Grid>
      </Grid>

      <Grid container spacing={2} maxWidth={'lg'} style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '100px', zIndex: 5 }}>
        {features.map((feature, index) => {
          return <Fragment key={index}>
            <Grid item xs={1} style={{ height: '180px' }}>
              <img src={feature.icon} alt={feature.name}/>
            </Grid>
            <Grid item xs={5}>
              <h3>{feature.name}</h3>
              <br/>
              {feature.description}
            </Grid>
          </Fragment>
        })}
      </Grid>

      {/* FOOTER */}
      <Grid container style={{ background: 'linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.5) 80.24%)', paddingTop: '128px', paddingBottom: '128px', marginTop: '64px' }}>
        <Grid item xs={12}>
          <Grid container spacing={4} maxWidth={'xl'} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <Grid item xs={6} md={3}>
              <img src={LogoFull} alt={'Mockresponse.io'}/>
              <br/>
              <br/>
              <br/>
              MockResponse.io © {(new Date).getFullYear()}
            </Grid>
            <Grid item xs={6} md={3}>
              <h4>Nav1</h4>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link1</Link></li>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link2</Link></li>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link3</Link></li>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link4</Link></li>
              </ul>
            </Grid>
            <Grid item xs={6} md={3}>
              <h4>Nav2</h4>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link1</Link></li>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link2</Link></li>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link3</Link></li>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link4</Link></li>
              </ul>
            </Grid>
            <Grid item xs={6} md={3}>
              <h4>Nav3</h4>
              <ul style={{ listStyle: 'none', padding: 0 }}>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link1</Link></li>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link2</Link></li>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link3</Link></li>
                <li style={{ marginTop: '16px' }}><Link to={'/'}>Link4</Link></li>
              </ul>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
