import { connect } from 'react-redux';
import Component from './PageTestCaseResult';
import { getTestCaseResult } from '../../store/testCase/selectors';
import { fetchTestCaseResult } from '../../store/testCase/actions';

const mapStateToProps = (state) => {
  return {
    getTestCaseResult: (testCaseResultId) => getTestCaseResult(state, testCaseResultId),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTestCaseResult: (testCaseResultId) => dispatch(fetchTestCaseResult(testCaseResultId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
