import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import SchemaProperty from '../../components/SchemaProperty';
import { deleteProperty, getProperty, setProperty } from 'dot-prop';

export default function SchemaEditor({ schema, updateSchema }) {
  const [schemaName, setSchemaName] = useState(schema.name);
  const [schemaContent, setSchemaContent] = useState(schema.content);
  const onSchemaNameChange = (event) => {
    setSchemaName(event.target.value);
  }

  const stateSchema = JSON.stringify({name: schema.name, content: schema.content});
  const updatedSchema = JSON.stringify({name: schemaName, content: schemaContent});
  console.log('equal', stateSchema === updatedSchema);

  console.log(
    stateSchema,
    updatedSchema,
  );

  const isSchemaUpdated = () => {
    return stateSchema !== updatedSchema;
  }

  const handleSchemaChange = () => {
    if (!isSchemaUpdated) {
      return;
    }

    updateSchema({
      id: schema.id,
      name: schemaName,
      content: schemaContent,
    });
  }

  const onSchemaUpdate = (dotpaths) => {
    let tmp = JSON.parse(JSON.stringify(schemaContent));

    dotpaths.forEach((dotpathObject) => {
      const dotpath = dotpathObject.dotpath.replace(/^\.+|\.+$/g, '');
      const dotpathToReplace = dotpathObject.dotpathToReplace ? dotpathObject.dotpathToReplace.replace(/^\.+|\.+$/g, '') : undefined;

      // replace
      if (dotpathObject.value === undefined && dotpathToReplace !== undefined) {
        const t = getProperty(tmp, dotpath);
        deleteProperty(tmp, dotpath);
        setProperty(tmp, dotpathToReplace, t);
      }
      else if (dotpathToReplace !== undefined) { // exchange key
        deleteProperty(tmp, dotpathToReplace);
        setProperty(tmp, dotpath, dotpathObject.value);
      }
      else if (dotpathObject.value !== undefined) { // set
        setProperty(tmp, dotpath, dotpathObject.value);
      }
      else { // delete
        deleteProperty(tmp, dotpath);
      }
    });

    setSchemaContent(tmp);
  }

  return (
    <>
      <Grid container>
        <Grid item style={{'width':'50%'}}>
          <label>
            Name
            <input
              type="text"
              name="name"
              onChange={onSchemaNameChange}
              onBlur={handleSchemaChange}
              required
              autoFocus
              value={schemaName}
            />
          </label>
          <br/>
          <label>
            Content
            <textarea
              name="content"
              readOnly
              value={JSON.stringify(schemaContent, null, 2)}
            />
          </label>
        </Grid>
        <Grid item style={{'width':'50%','overflowX':'auto'}}>
          <SchemaProperty
            type={schemaContent.type}
            required={schemaContent.required || undefined}
            properties={schemaContent.properties || undefined}
            items={schemaContent.items || undefined}
            onSchemaUpdate={onSchemaUpdate}
          />
          {isSchemaUpdated() ? <Button onClick={handleSchemaChange}>Updated, Save</Button> : <Button>Up to date</Button>}
        </Grid>
      </Grid>
    </>
  );
}
