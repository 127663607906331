import { connect } from 'react-redux';
import Component from './ModalProjectCreate';
import { createProject } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    createProject: (project, successCallback) => dispatch(createProject({ project, successCallback})),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

