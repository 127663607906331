import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { routes } from '../../config/routing/routing';
import '../../style/index.css';

export default function App({
  setAuthorization,
  isAuthorized,
}) {

  useEffect(() => {
    let token = localStorage.getItem('token');
    if (token) {
      setAuthorization(token);
    }
  })

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route) => {
          if (route.authorization === true && !isAuthorized) {
            return null;
          }

          return <Route
            key={route.name}
            path={route.path}
            exact={route.exact || false}
            element={route.component}
          />
        })}
      </Routes>
    </BrowserRouter>
  );
}

