import { connect } from 'react-redux';
import Component from './ModalEnvironmentCreate';
import { createEnvironment } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    createEnvironment: (environment, successCallback) => dispatch(createEnvironment({ environment, successCallback })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

