import React from 'react';

export default function ModalTemplate({ children }) {

  return (
    <div style={{
      position: 'absolute',
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(13, 16, 32, 0.75)',
    }}>
      <div style={{
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '5%',
        width: '500px',
        background: 'linear-gradient(118.58deg, rgb(31, 40, 64) 0%, rgb(25, 31, 54) 100%)',
        borderRadius: '15px',
        padding: '0px 16px'
      }}>
        {children}
      </div>
    </div>
  );
}
