import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from './store';
import App from 'components/App';
import i18next from 'i18next';
import translations from 'translations';
import { I18nextProvider } from 'react-i18next';
import { ThemeProvider } from '@mui/material';
import theme from 'theme';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'pl',
  resources: translations,
  // debug: true,
});

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ThemeProvider theme={theme}>
        <App/>
      </ThemeProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
