import { connect } from 'react-redux';
import Component from './Top';
import { setAuthorization } from '../../store/app/actions';
import { isAuthorized } from '../../store/app/selectors';

const mapStateToProps = (state) => {
  return {
    isAuthorized: isAuthorized(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setAuthorization: (token) => dispatch(setAuthorization(token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

