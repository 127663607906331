// import * as React from 'react';
// import muiColors from './config/material-ui/colors';
import { createTheme } from '@mui/material/styles';

const theme = {
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    fontSize: '2rem',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    // borderRadius: '25px',
                    fontSize: '5rem',
                    color: '#62e325',
                    height: '58px',
                },
                input: {
                    height: '25px',
                }
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: '#8e929e',
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                // Name of the slot
                root: {
                    // Some CSS
                    borderRadius: '25px',
                    fontSize: '5rem',
                    color: '#62e325',
                },
            },
        },
    },
};

// declare module '@material-ui/core/styles/createMuiTheme' {
//   interface ThemeOptions {
//     border: {
//       main: React.CSSProperties['color'];
//     };
//     disabledText: {
//       main: React.CSSProperties['color'];
//     };
//     textBackground: {
//       main: React.CSSProperties['color'];
//     };
//     layout: {
//       templatesTopBar: React.CSSProperties['color'];
//     };
//     nodes: {
//       simple: React.CSSProperties['color'];
//       shelf: React.CSSProperties['color'];
//       group: React.CSSProperties['color'];
//       functionType: React.CSSProperties['color'];
//     };
//   }
// }

export default createTheme(theme);
// export default null;
