import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import RouterHelper from '../../helpers/RouterHelper';
import Link from '../../common/Link';
import ResultStatus from '../../common/ResultStatus';
import Tile from '../../common/Tile';
import Top from '../../components/Top';
import LoupeIcon from '@mui/icons-material/Loupe';

export default function PageTestCaseResultList({
  getTestCaseResults,
  fetchTestCaseResults,
  getTestCase,
  fetchTestCase,
}) {

  const { projectId, testCaseId } = useParams();

  let testCaseResults = getTestCaseResults(testCaseId);
  const [fetched, setFetched] = useState(false);
  const testCase = getTestCase(testCaseId);

  useEffect(() => {
    if (!fetched) {
      fetchTestCaseResults(testCaseId);
      setFetched(true);
    }
    else if (!testCase) {
      fetchTestCase(testCaseId);
    }
  }, [fetched, testCase]);

  if (!testCaseResults || !testCase) {
    return <>Loading</>;
  }

  testCaseResults = [...testCaseResults].sort((testCaseResult1, testCaseResult2) => {
    if (testCaseResult1.createdAt === testCaseResult2.createdAt) {
      return 0;
    }

    return testCaseResult1.createdAt > testCaseResult2.createdAt ? -1 : 1;
  });

  return (
    <>
      <Top/>
      <Grid container>
        <Grid item xs={12}>
          <Link
            to={RouterHelper.generate('route_test_case',{'projectId': testCase.project, 'testCaseId':testCaseId})}
          >
            Edit Test case
          </Link>
        </Grid>
        {(testCaseResults || []).map(function(testCaseResult, index) {
          const backgroundColor = testCaseResult.status === 'success' ? '#1f2840' : '#401f2b';
          return (
            <Grid item xs={12} key={`${index}-${testCaseResult.id}`} data-id={testCaseResult.id}>
              <Tile styles={{
                background: `linear-gradient(118.86deg,${backgroundColor},#191f36)`,
              }}>
                <Grid container>
                  <Grid item xs={3}>
                    <ResultStatus status={testCaseResult.status}/>
                  </Grid>
                  <Grid item xs={3}>
                    {testCaseResult.duration} ms
                  </Grid>
                  <Grid item xs={3}>
                  <span style={{ color: testCaseResult.status === 'failed' ? '#f00' : '' }}>
                    {testCaseResult.testCaseStepResults.length}/{testCaseResult.stepsCount}
                  </span>
                  </Grid>
                  <Grid item xs={2}>
                    {testCaseResult.createdAt}
                  </Grid>
                  <Grid item xs={1}>
                    <Link
                      to={RouterHelper.generate('route_test_case_result_details',{'projectId': projectId,'testCaseResultId':testCaseResult.id})}
                      title={'Show details'}
                    >
                      <LoupeIcon/>
                    </Link>
                  </Grid>
                </Grid>
              </Tile>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
