import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import ButtonPlus from '../../assets/icons/button_plus.svg';
import modalHook from '../../hook/useModal';
import { useParams } from 'react-router-dom';
import Link from '../../common/Link';
import RouterHelper from '../../helpers/RouterHelper';
import ModalTestCaseCreate from '../../components/ModalTestCaseCreate';
import EditIcon from '../../assets/icons/edit.svg';
import Tile from '../../common/Tile';
import Top from '../../components/Top';
import ResultStatus from '../../common/ResultStatus';

export default function PageTestCaseList({ getTestCases, fetchTestCases }) {

  const { projectId } = useParams();

  let testCases = getTestCases(projectId);
  const [fetchedAll, setFetchedAll] = useState(false);

  useEffect(() => {
    if (!fetchedAll) {
      fetchTestCases(projectId);
      setFetchedAll(true);
    }
  }, [fetchedAll]);

  const [ModalTestCaseCreateComponent, handleOpenModalTestCaseCreate] = modalHook(ModalTestCaseCreate, {
    projectId,
  });

  if (!testCases) {
    return <>Loading</>;
  }

  testCases = [...testCases].sort((testCase1, testCase2) => {
    if (testCase1.name === testCase2.name) {
      return 0;
    }

    return testCase1.name > testCase2.name ? 1 : -1;
  });

  return (
    <>
      <Top/>
      <Grid container>
        {(testCases || []).map(function(testCase, index) {
          const backgroundColor = !testCase.lastTestCaseResult || testCase.lastTestCaseResult.status === 'success' ? '#1f2840' : '#401f2b';
          return (
            <Grid item xs={3} key={index} >
              <Tile styles={{
                background: `linear-gradient(118.86deg,${backgroundColor},#191f36)`,
              }}>
                {testCase.lastTestCaseResult ? <ResultStatus status={testCase.lastTestCaseResult.status}/> : ''}

                {testCase.name}
                <Link
                  to={RouterHelper.generate('route_test_case',{'projectId':projectId,'testCaseId':testCase.id})}
                >
                  <img src={EditIcon} alt={'Edit Test case'} style={{ padding: '0 8px' }}/>
                </Link>
                <Link
                  to={RouterHelper.generate('route_test_case_results',{'projectId': projectId, 'testCaseId':testCase.id})}
                >
                  Results
                </Link>
              </Tile>
            </Grid>
          );
        })}
        <img
          src={ButtonPlus}
          alt={'Create new project'}
          style={{ position: 'absolute', bottom: '24px', right: '48px'}}
          onClick={handleOpenModalTestCaseCreate}
        />
        {ModalTestCaseCreateComponent}
      </Grid>
    </>
  );
}
