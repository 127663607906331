import React, { useState } from 'react';
import { Grid } from '@mui/material';
import ResponseForm from '../../pages/PageProjectEditor/ResponseForm/ResponseForm';
import { HttpMethods } from '../../helpers/HttpHelper';
import Method from '../../common/Method';

export default function ResourceEditor({
  resource,
  updateResource,
  persistResource,
}) {

  if (!resource) {
    return (
      <>Choose resource</>
    );
  }

  const handleInputChange = (event) => {
    updateResource(resource.id, event.target.name, event.target.value);
  }

  const handleInputChanged = () => {
    persistResource();
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item style={{'width':'50%'}}>
            <Method method={resource.method}/>: {resource.path}
          <br/>

          <br/>
          {resource.description}
          <br/>
          <pre>{JSON.stringify(resource.content || "{}", null, 2)}</pre>
        </Grid>
        <Grid item style={{'width':'50%'}}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <label>
                <select
                  name="method"
                  onChange={handleInputChange}
                  onBlur={handleInputChanged}
                  value={resource.method}
                >
                  {HttpMethods.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </label>
            </Grid>
            <Grid item xs={9}>
              <label>
                <input
                  type="text"
                  name="path"
                  onChange={handleInputChange}
                  onBlur={handleInputChanged}
                  required
                  autoFocus
                  value={resource.path}
                />
              </label>
            </Grid>
          </Grid>


          <br/>
          <label>
            name
            <input
              type="text"
              name="name"
              onChange={handleInputChange}
              onBlur={handleInputChanged}
              value={resource.name}
            />
          </label>
          <br/>
          <label>
            Operation id
            <input
              type="text"
              name="operationId"
              onChange={handleInputChange}
              onBlur={handleInputChanged}
              value={resource.operationId}
            />
          </label>
          <br/>
          <label>
            description
            <input
              type="text"
              name="content.description"
              onChange={handleInputChange}
              onBlur={handleInputChanged}
              value={resource.content?.description}
            />
          </label>
          {(resource.content?.responses || []).map((response, index) => {
            // console.log(response);
            return <ResponseForm
              key={index}
              response={response}
              onResponseChange={onResponseChange}
            />
          })}
        </Grid>
      </Grid>
    </>
  );
}
