import { connect } from 'react-redux';
import Component from './PageEnvironmentsEditor';
import { getEnvironments } from '../../store/app/selectors';
import { fetchEnvironments, setResources } from '../../store/app/actions';

const mapStateToProps = (state) => {
  return {
    environments: getEnvironments(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    // resetResources: () => dispatch(setResources([])),
    fetchEnvironments: (projectId) => dispatch(fetchEnvironments(projectId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
