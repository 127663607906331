import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import Top from '../../components/Top';

export default function PageOrganizationSetting({
  organizations,
  fetchUser,
}) {
  useEffect(() => {
    if (!organizations) {
      fetchUser();
    }
  }, [organizations]);

  if (!organizations) {
    return <>Loading</>;
  }

  let formatMilliseconds = function (milliseconds) {
    let reversed = (milliseconds + '').split('').reverse().join('');
    reversed = reversed.match(/.{1,3}/g).join(' ');
    reversed = reversed.split('').reverse().join('');

    return reversed;
  }

  let millisecondsToTime = function (milliseconds) {
    let timestamp = Math.floor(milliseconds / 100);
    let zm = Math.round((timestamp / 100 - Math.floor(timestamp / 100)) * 100);
    let days = Math.floor(timestamp / 86400);
    timestamp -= days * 86400;
    let hours = Math.floor(timestamp / 3600);
    timestamp -= hours * 60 * 60;
    let minutes = Math.floor(timestamp / 60);
    let seconds = Math.floor(timestamp - minutes * 60);

    return days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's ' + zm + 'ms';
  }

  const organization = organizations[0]; // for now only one organization

  return (
    <>
      <Top/>
      <Grid container>
        <Grid item>
          <h4>{organization.name}</h4>
          <span>{formatMilliseconds(organization.availableExecutionTime)} ms ({millisecondsToTime(organization.availableExecutionTime)})</span>
        </Grid>
      </Grid>
    </>
  );
}
