

export function getApiHost({ app }) {
  return app.config.apiHost;
}

export function getApiToken({ app }) {
  return app.config.apiToken;
}

export function isAuthorized({ app }) {
  if (!app.config.apiToken) {
    return false;
  }

  return typeof app.config.apiToken === 'string';
}

export function getProjects({ app }) {
  return app.projects;
}

export function getEnvironments({ app }) {
  return app.environments;
}

export function getResources({ app }) {
  return app.resources;
}

export function getCurrentResource({ app }) {
  return app.currentResource;
}

export function getSchemas({ app }) {
  return app.schemas;
}

export function getCurrentSchema({ app }) {
  return app.currentSchema;
}

export function getOrganizations({ app }) {
  return app.organizations;
}
