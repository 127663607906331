import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import RouterHelper from '../../helpers/RouterHelper';
import Link from '../../common/Link';
import ButtonPlus from '../../assets/icons/button_plus.svg';
import modalHook from '../../hook/useModal';
import Tile from '../../common/Tile';
import Top from '../../components/Top';
import { useParams } from 'react-router-dom';
import ModalEnvironmentCreate from '../../components/ModalEnvironmentCreate';

export default function PageEnvironmentsList({ environments, fetchEnvironments }) {

  const { projectId } = useParams();
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    if (!fetched){
      fetchEnvironments(projectId);
      setFetched(true);
    }
  });

  const [ModalEnvironmentCreateComponent, handleOpenModalEnvironmentCreate] = modalHook(ModalEnvironmentCreate, { projectId });

  if (!environments) {
    return <div>Loading</div>;
  }

  console.log(environments, projectId);

  const projectEnvironments = environments.filter((environment) => {
    return environment.project === projectId;
  })

  return (
    <>
      <Top/>
      <Grid container style={{ padding: '16px' }}>
        {projectEnvironments.length === 0 ? 'No results' : ''}
        {projectEnvironments.map((environment, index) => {
          return (
            <Grid item xs={3} key={index} data-id={environment.id}>
              <Tile styles={{ 'padding':'0', }}>
                <div style={{ padding: '32px', height: '40px' }}>
                  <span style={{
                    'width':'6px',
                    'height':'6px',
                    'borderRadius':'6px',
                    'backgroundColor':'#cdff05',
                    'float':'left',
                    'marginTop':'10px',
                    'marginRight':'10px',
                  }}/>

                  <span style={{
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '20px',
                    lineHeight: '25px',
                    color: '#fff',
                    marginBottom: '32px'
                  }}>
                    <Link
                      to={RouterHelper.generate('route_environment_edition', {'projectId':projectId,'environmentId':environment.id})}
                      style={{ color: '#ffffff',  }}
                    >
                      {environment.name}
                    </Link>
                  </span>
                  <br/>
                  {environment.host}
                </div>
              </Tile>
            </Grid>
          );
        })}
        <img
          src={ButtonPlus}
          alt={'Create new Environment'}
          style={{ position: 'absolute', bottom: '24px', right: '48px'}}
          onClick={handleOpenModalEnvironmentCreate}
          className={'cursor'}
        />
        {ModalEnvironmentCreateComponent}
      </Grid>
    </>
  );
}
