import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import ResourceEditor from '../../components/ResourceEditor';
import { useParams } from 'react-router-dom';
import ResourcesList from '../../components/ResourcesList';
import SchemasList from '../../components/SchemasList';
import SchemaEditor from '../../components/SchemaEditor';
import Top from '../../components/Top';

export default function PageProjectEditor({
  schemas,
  resources,
  fetchSchemas,
  fetchResources,
  currentResource,
  currentSchema,
}) {

  const { projectId } = useParams();

  useEffect(() => {
    if (!resources) {
      fetchResources();
    }
    else if (!schemas) {
      fetchSchemas(projectId);
    }
  }, [resources, schemas]);

  if (!resources) {
    return <>Loading</>;
  }

  const projectResources = resources.filter((resource) => {
    return resource.project === projectId;
  })

  return (
    <>
      <Top/>
      <Grid container>
        <Grid item style={{'width':'260px','zIndex':100}}>
          <h4>Api Overview</h4>
          <ResourcesList resources={projectResources} projectId={projectId}/>
          <SchemasList schemas={schemas} projectId={projectId}/>
        </Grid>
        <Grid item style={{'position':'absolute','top':'100px','width':'100%','paddingLeft':'300px'}}>
          {currentResource ? <ResourceEditor resource={currentResource}/> : ''}
          {currentSchema ? <SchemaEditor schema={currentSchema}/> : ''}
        </Grid>
      </Grid>
    </>
  );
}
