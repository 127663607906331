import { createReducer } from '@reduxjs/toolkit';
import {
    addProject,
    addSchema,
    setAuthorization,
    setCurrentResource,
    setCurrentSchema,
    setEnvironmentById,
    setEnvironments, setOrganizations,
    setProjects,
    setResources,
    setSchemaById,
    setSchemas,
    storeInit,
    updateResource
} from './actions';
import initialAppState from './defaultState';
import {
    Environment, Organization,
    Project,
    Resource,
    Schema,
    StoreInitActionPayload,
    UpdateResourceInterface
} from './interfaces';
import {setProperty} from 'dot-prop';

export default createReducer(initialAppState, (builder) => {
  builder
    .addCase(storeInit, (draft, { payload }: { payload: StoreInitActionPayload }) => {
      const { config } = payload;
      draft.config = config;
    })
    .addCase(setAuthorization, (draft, { payload }: { payload: string|undefined }) => {
        if (typeof payload === 'string') {
            draft.config.apiToken = `Bearer ${payload}`;
        }
        else {
            draft.config.apiToken = undefined;
        }
    })
    .addCase(setProjects, (draft, { payload }: { payload: Project[] }) => {
        draft.projects = payload;
    })
    .addCase(addProject, (draft, { payload }: { payload: Project }) => {
        if (draft.projects === undefined) {
            draft.projects = [];
        }

        draft.projects.push(payload);
    })
    .addCase(setResources, (draft, { payload }: { payload: Resource[] }) => {
        draft.resources = draft.resources ?? [];

        payload
            .map((resource) => {
                draft.resources = draft.resources ?? [];

                const index = draft.resources.findIndex((r) => {
                    return r.id === resource.id;
                })

                if (-1 !== index) {
                    draft.resources[index] = resource;
                }
                else {
                    draft.resources.push(resource);
                }
            })
        ;
    })
    .addCase(setCurrentResource, (draft, { payload }: { payload: Resource|undefined }) => {
        console.log('setCurrentResource', payload);
        draft.currentSchema = undefined;
        draft.currentResource = payload;
    })
    .addCase(setSchemas, (draft, { payload }: { payload: Schema[]|undefined }) => {
        console.log('setSchemas', payload);
        draft.schemas = payload;
    })
    .addCase(setCurrentSchema, (draft, { payload }: { payload: Schema|undefined }) => {
        console.log('setCurrentSchema', payload);
        draft.currentResource = undefined;
        draft.currentSchema = payload;
    })
    .addCase(updateResource, (draft, { payload }: { payload: UpdateResourceInterface }) => {
        console.log('updateResource', payload);
        if (typeof draft.currentResource === 'undefined') {
            return;
        }

        console.log('updatingResource', draft.currentResource);
        setProperty(draft.currentResource, payload.dotpath, payload.value);
        console.log('updated', draft.currentResource);
    })
    .addCase(addSchema, (draft, { payload }: { payload: Schema }) => {
        if (draft.schemas === undefined) {
            draft.schemas = [];
        }

        draft.schemas.push(payload);
    })
    .addCase(setSchemaById, (draft, { payload }: { payload: Schema }) => {
        if (draft.schemas === undefined) {
            draft.schemas = [];
        }

        const index = draft.schemas.findIndex((schema) => {
            return schema.id === payload.id;
        })

        if (-1 !== index) {
            draft.schemas[index] = payload;
        }
        else {
            draft.schemas.push(payload);
        }
    })
    .addCase(setEnvironments, (draft, { payload }: { payload: Environment[] }) => {
        console.log('setEnvironments', payload);
        payload.forEach((environment) => {
            const index = draft.environments.findIndex((env) => {
                return env.id === environment.id;
            })

            if (-1 !== index) {
                draft.environments[index] = environment;
            }
            else {
                draft.environments.push(environment);
            }
        })
    })
    .addCase(setOrganizations, (draft, { payload }: { payload: Organization[] }) => {
        console.log('setOrganizations', payload);
        draft.organizations = draft.organizations ?? [];
        payload
            .forEach((environment) => {
                draft.organizations = draft.organizations ?? [];

                const index = draft.organizations.findIndex((env) => {
                    return env.id === environment.id;
                })

                if (-1 !== index) {
                    draft.organizations[index] = environment;
                }
                else {
                    draft.organizations.push(environment);
                }
            });
    })
    .addCase(setEnvironmentById, (draft, { payload }: { payload: Environment }) => {
        if (draft.environments === undefined) {
            draft.environments = [];
        }

        const index = draft.environments.findIndex((environment) => {
            return environment.id === payload.id;
        })

        if (-1 !== index) {
            draft.environments[index] = payload;
        }
        else {
            draft.environments.push(payload);
        }
    })
  ;
});
