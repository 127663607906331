import React, { useState } from 'react';
import { Grid } from '@mui/material';

export default function ModalSchemaCreate({
  createSchema,
  handleClose,
  projectId,
}) {

  const [schemaName, setSchemaName] = useState('');

  const handleSchemaName = (e) => {
    setSchemaName(e.target.value);
  };

  const handleCreateSchema = () => {
    const schema = {
      name: schemaName,
      project: projectId,
    };

    createSchema(schema, handleClose);
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <h3 style={{ fontSize: '18px' }}>
            Create new Schema
          </h3>
        </Grid>
        <Grid item xs={4} style={{ textAlign: 'right', paddingTop: '16px' }}>
          <i
            style={{
              cursor: 'pointer',
              padding: '6px 10px 8px 8px',
              borderRadius: '15px',
              background: 'linear-gradient(146.06deg, rgb(61, 70, 95) 0%, rgb(25, 31, 54) 100%)'
            }}
            onClick={handleClose}
          >🗙</i>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11}>
          <input
            type={'text'}
            name={'schema.name'}
            placeholder={'Name'}
            value={schemaName}
            onChange={handleSchemaName}
          />
        </Grid>
      </Grid>

      <button onClick={handleCreateSchema}>
        Create
      </button>
    </>
  );
}
