import { all } from 'redux-saga/effects';
import app from './app/sagas';
import testCase from './testCase/sagas';

export default function* rootSaga() {
  yield all([
    ...app,
    ...testCase,
  ]);
}

