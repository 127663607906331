import { connect } from 'react-redux';
import Component from './PageTestCaseResultList';
import { getTestCaseById, getTestCaseResults } from '../../store/testCase/selectors';
import { fetchTestCase, fetchTestCaseResults } from '../../store/testCase/actions';

const mapStateToProps = (state) => {
  return {
    getTestCaseResults: (testCaseId) => getTestCaseResults(state, testCaseId),
    getTestCase: (testCaseId) => getTestCaseById(state, testCaseId),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTestCaseResults: (testCaseId) => dispatch(fetchTestCaseResults(testCaseId)),
    fetchTestCase: (testCaseId) => dispatch(fetchTestCase(testCaseId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
