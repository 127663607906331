import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import TestCaseForm from '../../pages/PageTestCaseEditor/TestCaseForm';
import ButtonPlus from '../../assets/icons/button_plus.svg';
import TestCaseStepForm from '../../pages/PageTestCaseEditor/TestCaseStepForm';
import Tile from '../../common/Tile';
import Top from '../../components/Top';
import Link from '../../common/Link';
import RouterHelper from '../../helpers/RouterHelper';

export default function PageTestCaseEditor({
  getTestCase,
  resources,
  fetchTestCase,
  fetchResources,
  createTestCaseStep,
  removeTestCaseStep,
}) {

  const { testCaseId } = useParams();

  const testCase = getTestCase(testCaseId);

  useEffect(() => {
    if (!resources) {
      fetchResources();
    }
    else if (!testCase) {
      fetchTestCase(testCaseId);
    }
  }, [resources, testCase]);

  if (!testCase || !resources) {
    console.log(testCase, resources);
    return <>Loading...</>
  }

  const projectResources = resources.filter((resource) => {
    return resource.project === testCase.project;
  });

  const onCreateTestCaseStep = (e) => {
    const position = parseInt(e.target.dataset['position']);
    console.log(position);
    createTestCaseStep(testCase.project, testCaseId, {
      testCase: testCaseId,
      expectedResponseStatusCode: 200,
      position: position + 1,
      resource: projectResources[0].id,
    });
  }

  const onRemoveTestCaseStep = (e, step) => {
    removeTestCaseStep({ testCaseStepId: step.id, testCaseId, projectId: testCase.project });
  }

  const testCaseSteps = [...testCase.testCaseSteps].sort((testCaseStep1, testCaseStep2) => {
    if (testCaseStep1.position === testCaseStep2.position) {
       return 0;
    }

    return testCaseStep1.position > testCaseStep2.position ? 1 : -1;
  })

  return (
    <>
      <Top/>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Link to={RouterHelper.generate('route_test_case_results',{'projectId':testCase.project,'testCaseId':testCase.id})}>
            Results
          </Link>
          <TestCaseForm projectId={testCase.project} testCase={testCase}/>
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ textAlign: 'center' }}>
                <img
                  src={ButtonPlus}
                  alt={'Add new step'}
                  style={{ width: '32px' }}
                  data-position={0}
                  onClick={onCreateTestCaseStep}
                />
              </div>
            </Grid>
            {testCaseSteps.map(function(testCaseStep, index) {
              return (
                <Grid item xs={12} key={`${index}-${testCaseStep.position}-${testCaseStep.id}`}>
                  <Tile>
                    <TestCaseStepForm
                      resources={projectResources}
                      testCaseStep={testCaseStep}
                      projectId={testCase.project}
                      testCaseId={testCaseId}
                      onRemoveTestCaseStep={onRemoveTestCaseStep}
                    />
                  </Tile>
                  <div style={{ textAlign: 'center' }}>
                    <img
                      src={ButtonPlus}
                      alt={'Add new step'}
                      style={{ width: '32px' }}
                      data-position={testCaseStep.position}
                      onClick={onCreateTestCaseStep}
                    />
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
