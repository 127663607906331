import React, { useState } from 'react';
import { Grid } from '@mui/material';
import Link from '../../common/Link';
import RouterHelper from '../../helpers/RouterHelper';
import LogoFull from '../../assets/icons/logo_full.svg';
import Shape1 from '../../assets/shapes/shape1.svg';
import Shape2 from '../../assets/shapes/shape2.svg';
import Shape3 from '../../assets/shapes/shape3.svg';
import Shape4 from '../../assets/shapes/shape4.svg';
import { Navigate } from 'react-router-dom';

export default function PageAuthorize({ authorize, isAuthorized }) {

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  if (isAuthorized) {
    return <Navigate replace to={RouterHelper.generate('route_projects_list')} />
  }

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  }

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  }

  const onAuthorize = () => {
    authorize({
      username,
      password,
    })
  }

  return (
    <>
      <div style={{ background: 'radial-gradient(36.01% 36.01% at 50% 44.23%, #405796 0%, #0D1020 100%)', zIndex: '0', opacity: '0.15', height: '100%', minHeight: '900px', width: '100%', position: 'relative', marginLeft: 'auto', marginRight: 'auto' }}/>
      <div style={{ height: '100%', minHeight: '900px', width: '100%', position: 'absolute', top: 0, marginLeft: 'auto', marginRight: 'auto' }}>

        <img src={Shape3} alt={'MockResponse'} style={{ position: 'absolute', top: '0', left: '0', opacity: '10%', zIndex: '-5' }}/>
        <img src={Shape4} alt={'MockResponse'} style={{ position: 'absolute', bottom: '-80px', left: '0',  }}/>

        <div style={{ marginLeft: 'auto', marginRight: 'auto', height: '100%', width: '900px' }}>
          <div style={{ position: 'absolute', width: '900px', textAlign: 'center', top: '110px' }}>
            {/*<router-link :to="{ name: 'route_home' }">*/}
            {/*<LogoFull height="58px" style=""></LogoFull>*/}
            <Link to={RouterHelper.generate('route_projects_list')}>
              <img src={LogoFull} alt={'MockResponse'} style={{ width: '295px'}}/>
            </Link>
          </div>

          <div style={{ position: 'absolute', zIndex: '10000', width: '900px', top: '240px' }}>
            <div style={{ marginLeft:'auto', marginRight: 'auto', width: '454px', padding: '40px', background: 'linear-gradient(118.58deg, #1F2840 0%, #191F36 100%)', borderRadius: '10px' }}>
              <img src={Shape1} alt={'MockResponse'} style={{ position: 'absolute', top: '-70px', right: '60px', zIndex: '-5' }}/>
              <img src={Shape2} alt={'MockResponse'} style={{ position: 'absolute', bottom: '-130px', left: '70px', zIndex: '-5' }}/>
              <Grid container style={{ xIndex: 100 }}>
                <Grid item xs={6}>
                  <h1 style={{ fontSize: '24px', weight: 600 }}>Sign in</h1>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: '15px', fontSize: '14px', weight: 600 }}>
                  <Link to={RouterHelper.generate('route_login')}>
                    Don't have account yet?
                  </Link>
                  {/*<router-link :to="{ name: 'route_register' }" style="font-family:Encode Sans;color: #8F9FCA;font-style:normal;font-weight: 600;font-size:14px;line-height: 17px;">*/}

                  {/*</router-link>*/}
                </Grid>
              </Grid>
                <input type="email" value={username} placeholder={'Email'} autoComplete={'off'} onChange={onUsernameChange}/>
                {/*<div>Email is required</div>*/}
                {/*<div>Bad credentials</div>*/}
                <input type="password" value={password} placeholder={'Password'} autoComplete={'off'} onChange={onPasswordChange}/>
                {/*<div>Password is required</div>*/}
                <button onClick={onAuthorize}>Sign in</button>
            </div>
            <div>
              <span>MockResponse.io &copy;</span>
            </div>
          </div>

        </div>
      </div>
    </>
  );
}
