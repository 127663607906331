import { connect } from 'react-redux';
import Component from './TestCaseForm';
import { updateTestCase } from '../../../store/testCase/actions';
import { getEnvironments } from '../../../store/app/selectors';

const mapStateToProps = (state) => {
  return {
    environments: getEnvironments(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateTestCase: (projectId, testCaseId, testCase) => dispatch(updateTestCase({projectId, testCaseId, testCase})),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
