
const HttpMethods = [
  {
    label: "POST",
    value: "post",
  },
  {
    label: "GET",
    value: "get",
  },
  {
    label: "PATCH",
    value: "patch",
  },
  {
    label: "PUT",
    value: "put",
  },
  {
    label: "DELETE",
    value: "delete",
  },
];

const HttpMethodsColors = {
  post: '#67de9a',
  get: '#3d97ed',
  delete: '#ea4646',
  patch: '#dec267',
  put: '#e19f37',
  option: '#3d97ed',
  head: '#3d97ed',
}

const HttpCodes = [
  {
    label: "200 OK",
    value: 200,
  },
  {
    label: "201 Created",
    value: 201,
  },
  {
    label: "202 Accepted",
    value: 202,
  },
  {
    label: "204 No content",
    value: 204,
  },
  {
    label: "300 Multiple Choices",
    value: 300,
  },
  {
    label: "301 Moved Permanently",
    value: 301,
  },
  {
    label: "400 Bad Request",
    value: 400,
  },
  {
    label: "401 Unauthorized",
    value: 401,
  },
  {
    label: "403 Forbidden",
    value: 403,
  },
  {
    label: "404 Not Found",
    value: 404,
  },
  {
    label: "405 Method Not Allowed",
    value: 405,
  },
  {
    label: "409 Conflict",
    value: 409,
  },
  {
    label: "413 Entity too large",
    value: 413,
  },
  {
    label: "415 Unsupported Media Type",
    value: 415,
  },
  {
    label: "422 Unprocessable Entity",
    value: 422,
  },
  {
    label: "501 Not Implemented",
    value: 501,
  },
];

export class HttpHelper {


  // const ;

  // static formatMoney = (price) => {
  //   return new Intl.NumberFormat('pl-PL', {
  //     style: 'currency',
  //     currency: price.currency.toUpperCase(),
  //   }).format(price.amount);
  // };

}

export { HttpMethods, HttpMethodsColors, HttpCodes,  }
