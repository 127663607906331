import PageAuthorize from '../../pages/PageAuthorize';
import PageProjectsList from '../../pages/PageProjectsList';
import PageProjectEditor from '../../pages/PageProjectEditor';
import PageTestCaseList from '../../pages/PageTestCaseList';
import PageTestCaseEditor from '../../pages/PageTestCaseEditor';
import PageTestCaseResultList from '../../pages/PageTestCaseResultList';
import PageTestCaseResult from '../../pages/PageTestCaseResult';
import PageHome from '../../pages/PageHome';
import PageNotFound from '../../pages/PageNotFound';
import PageEnvironmentsList from '../../pages/PageEnvironmentsList';
import PageEnvironmentsEditor from '../../pages/PageEnvironmentsEditor';
import PageSignUp from '../../pages/PageSignUp';
import PageOrganizationSetting from '../../pages/PageOrganizationSetting';

const routes = [
  {
    name: 'route_homepage',
    path: '/',
    component: <PageHome/>,
    exact: true,
    authorization: false,
  },
  {
    name: 'route_login',
    path: '/login',
    component: <PageAuthorize/>,
    exact: true,
    top: false,
    authorization: false,
  },
  {
    name: 'route_register',
    path: '/register',
    component: <PageSignUp/>,
    exact: true,
    authorization: false,
  },
  {
    name: 'route_projects_list',
    path: '/projects',
    component: <PageProjectsList/>,
    exact: true,
    authorization: true,
  },
  {
    name: 'route_project_editor',
    path: '/projects/:projectId/resources',
    component: <PageProjectEditor/>,
    exact: true,
    authorization: true,
  },
  {
    name: 'route_test_suites',
    path: '/projects/:projectId/test-cases',
    component: <PageTestCaseList/>,
    exact: true,
    authorization: true,
  },
  {
    name: 'route_organization_settings',
    path: '/organizations',
    component: <PageOrganizationSetting/>,
    exact: true,
    authorization: true,
  },
  {
    name: 'route_test_case',
    path: '/projects/:projectId/test-cases/:testCaseId',
    component: <PageTestCaseEditor/>,
    exact: true,
    authorization: true,
  },
  {
    name: 'route_test_case_results',
    path: '/projects/:projectId/test-cases/:testCaseId/results',
    component: <PageTestCaseResultList/>,
    exact: true,
    authorization: true,
  },
  {
    name: 'route_test_case_result_details',
    path: '/projects/:projectId/test-case-results/:testCaseResultId',
    component: <PageTestCaseResult/>,
    exact: true,
    authorization: true,
  },
  {
    name: 'route_environment_list',
    path: '/projects/:projectId/environments',
    component: <PageEnvironmentsList/>,
    exact: true,
    authorization: true,
  },
  {
    name: 'route_environment_edition',
    path: '/projects/:projectId/environments/:environmentId',
    component: <PageEnvironmentsEditor/>,
    exact: true,
    authorization: true,
  },
  {
    name: 'route_dynamic_match',
    path: '/*',
    component: <PageNotFound/>,
    exact: true,
    authorization: false,
  },
];

export { routes };
