import React from 'react';
import LogoFull from '../../components/Icons/LogoFull';
import LogoutIcon from '../../assets/icons/logout.svg';
import { Grid } from '@mui/material';
import Link from '../../common/Link';
import RouterHelper from '../../helpers/RouterHelper';
import { useNavigate, useParams } from 'react-router-dom';
import PencilIcon from '../../assets/icons/pencil.svg';
import MonitoringIcon from '../../assets/icons/monitoring.svg';

export default function Top({
  setAuthorization,
  isAuthorized,
}) {

  const navigate = useNavigate();
  const { projectId } = useParams();

  const onLogout = () => {
    localStorage.removeItem('token');
    setAuthorization(undefined);
    navigate(RouterHelper.generate('route_homepage'))
  }

  const projectNavigation = function() {
    if (!projectId) {
      return null;
    }

    return (
      <>
        <Link className={RouterHelper.isOneOf(['route_environment_list','route_environment_edition']) ? 'active' : ''}  to={RouterHelper.generate('route_environment_list', {'projectId':projectId})}>
          <img src={PencilIcon} alt={'Define environments'} style={{ marginRight: '16px', width: '20px', height: '20px', verticalAlign: 'middle' }}/> Environments
        </Link>
        <Link className={RouterHelper.isOneOf(['route_project_editor']) ? 'active' : ''}  to={RouterHelper.generate('route_project_editor', {'projectId':projectId})}>
          <img src={PencilIcon} alt={'Define requests'} style={{ marginRight: '16px', width: '20px', height: '20px', verticalAlign: 'middle' }}/> Requests
        </Link>
        <Link className={RouterHelper.isOneOf(['route_test_suites', 'route_test_case', 'route_test_case_results', 'route_test_case_result_details']) ? 'active' : ''} to={RouterHelper.generate('route_test_suites', {'projectId': projectId})}>
          <img src={MonitoringIcon} alt={'Test cases'} style={{ marginRight: '16px', width: '20px', height: '20px', verticalAlign: 'middle' }}/> Test Cases
        </Link>
      </>
    )
  }

  return (
    <div className={'top'}>
      <Grid container>
        <Grid item xs={4}>
          <Link path={RouterHelper.generate('route_homepage')}>
            <LogoFull/>
          </Link>
        </Grid>
        <Grid item xs={8} style={{ padding:'10px 16px', textAlign: 'right' }}>
          <div id={'top'}>
            {projectNavigation()}
            <a href={RouterHelper.generate('route_organization_settings')}>
              settings
            </a>
            {isAuthorized ? <img src={LogoutIcon} alt={'Logout'} title={'Logout'} className={'cursor'} onClick={onLogout}/> : ''}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
