import { connect } from 'react-redux';
import Component from './PageSignUp';
import { createAccount } from '../../store/app/actions';
import { isAuthorized } from '../../store/app/selectors';

const mapStateToProps = (state) => {
  return {
    isAuthorized: isAuthorized(state),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    createAccount: (credentials) => dispatch(createAccount(credentials)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);

