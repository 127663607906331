import React, { useEffect, useState } from 'react';
import ModalTemplate from '../components/ModalContainer';

export default function useModal(Modal, ModalProps) {

  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  }

  const handleOpenModal = () => {
    setVisible(true);
    console.log('open.modal');
  }

  const escFunction = (event) => {
    if (event.key === "Escape") {
      handleClose();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    }
  }, []);

  const Component = visible ? <ModalTemplate>
    <Modal {...ModalProps} handleClose={handleClose}/>
  </ModalTemplate> : null;

  return [
    Component,
    handleOpenModal,
  ];
}
