import React, { useEffect, useState } from 'react';
import { handleError, handleResponse } from '../../../api/api';

export default function TestCaseForm({ testCase, projectId, updateTestCase }) {

  const [testCaseState, setTestCaseState] = useState(testCase);
  const [environments, setEnvironments] = useState(undefined);

  useEffect(async () => {
    if (environments === undefined) {
      setEnvironments(
        await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/projects/${projectId}/environments`, {
          'headers': {
            'Accept': 'application/json',
          }
        })
          .then(handleResponse)
          .catch(handleError)
        );
    }

  }, [environments]);

  if (environments === undefined) {
    return <>Loading</>
  }

  console.log('environments', environments);

  const onChange = (e) => {
    const tmp = { ...testCaseState, ...{[e.target.name]: e.target.value} };
    setTestCaseState(tmp);
  }

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    updateTestCase(projectId, testCase.id, {[e.target.name]: e.target.value});
  }

  const handleChangeSelect = (e) => {
    const tmp = { ...testCaseState, ...{[e.target.name]: e.target.value} };
    setTestCaseState(tmp);
    updateTestCase(projectId, testCase.id, {[e.target.name]: e.target.value});
  }

  const EnvironmentSelect = () => {
    if (environments.length === 0) {
      return <select>
        <option>missing environments, test will not be executed</option>
      </select>;
    }

    return <select name={'environment'} onChange={handleChangeSelect} defaultValue={testCaseState.environment}>
      {environments.map((environment) => {
        return <option key={environment.id} value={environment.id}>
          {environment.name}
        </option>
      })}
    </select>
  }

  return (
    <>
      <input type={'text'} value={testCaseState.name} name={'name'} onChange={onChange} onBlur={handleChange} autoComplete={'off'}/>
      <EnvironmentSelect/>
      <textarea value={testCaseState.description} name={'description'} onChange={onChange} onBlur={handleChange} autoComplete={'off'}/>
    </>
  );
}
