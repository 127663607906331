import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import RouterHelper from '../../helpers/RouterHelper';
import Link from '../../common/Link';
import Method from '../../common/Method';
import ResultStatus from '../../common/ResultStatus';
import Top from '../../components/Top';

export default function PageTestCaseResult({ getTestCaseResult, fetchTestCaseResult }) {

  const { testCaseResultId } = useParams();

  let testCaseResult = getTestCaseResult(testCaseResultId);

  useEffect(() => {
    if (!testCaseResult) {
      fetchTestCaseResult(testCaseResultId);
    }
  }, [testCaseResult]);

  if (!testCaseResult) {
    return <>Loading</>;
  }

  let testCaseStepResults = [...testCaseResult.testCaseStepResults].sort((testCaseStepResult1, testCaseStepResult2) => {
    if (testCaseStepResult1.position === testCaseStepResult2.position) {
      return 0;
    }

    return testCaseStepResult1.position > testCaseStepResult2.position ? 1 : -1;
  })

  return (
    <>
      <Top/>
      <Grid container>
        <Grid item xs={12}>
          <Link
            to={RouterHelper.generate('route_test_case',{'projectId': testCaseResult.project, 'testCaseId':testCaseResult.testCase.id})}
          >
            Edit Test case
          </Link>
          <Link
            to={RouterHelper.generate('route_test_case_results',{'projectId':testCaseResult.testCase.project,'testCaseId':testCaseResult.testCase.id})}
          >
            Test case results
          </Link>
        </Grid>
        {testCaseStepResults.map(function(testCaseStepResult, index) {
          let responseBody = '';

          if (testCaseStepResult.responseBody) {
            try {
              responseBody = JSON.stringify(JSON.parse(testCaseStepResult.responseBody), null, 2)
            }
            catch (e) {
              responseBody = testCaseStepResult.responseBody;
            }
          }

          const resource = testCaseStepResult.testCaseStep.resource;
          const success = testCaseStepResult.responseStatusCode === testCaseStepResult.expectedStatusCode ? 'success' : 'failed';
          const backgroundColor = success === 'success' ? '#1f2840' : '#401f2b';

          return (
            <Grid item xs={12} key={index} data-id={testCaseStepResult.id}>
              <div style={{
                'background':`linear-gradient(118.86deg,${backgroundColor},#191f36)`,
                'borderRadius':'10px',
                'padding':'32px',
                'color':'#fff',
                'margin': '8px',
              }}>
                <Grid container>
                  <Grid item xs={2}>
                    <ResultStatus status={success}/>
                    <br/>
                    {resource.name}
                    <br/>
                    <Method method={resource.method}/> {resource.path}
                    <br/>
                    {testCaseStepResult ? testCaseStepResult.responseTime + ' ms' : ''}
                    <br/>
                    {testCaseStepResult ? testCaseStepResult.responseSize + ' bytes' : ''}
                    <br/>
                    {testCaseStepResult ? testCaseStepResult.responseStatusCode : '---'} / {testCaseStepResult ? testCaseStepResult.expectedStatusCode : '---'}
                    <br/>
                    {testCaseStepResult ? testCaseStepResult.createdAt : ''}
                  </Grid>
                  <Grid item xs={4}>
                    Request url:
                    <br/>
                    {testCaseStepResult ? testCaseStepResult.requestUrl : ''}
                    <br/>
                    <br/>
                    Request headers:
                    <br/>
                    <pre>{testCaseStepResult ? JSON.stringify(testCaseStepResult.requestHeaders, null, 2) : ''}</pre>
                    <br/>
                    Request body:
                    <br/>
                    <pre>{testCaseStepResult ? JSON.stringify(testCaseStepResult.requestBody, null, 2) : ''}</pre>
                  </Grid>
                  <Grid item xs={5} style={{}}>
                    Response body:
                    <br/>
                    <pre>{responseBody}</pre>
                    <br/>
                    Response headers:
                    <br/>
                    <pre>{testCaseStepResult ? JSON.stringify(testCaseStepResult.responseHeaders, null, 2) : ''}</pre>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
