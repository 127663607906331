import {createAction} from '@reduxjs/toolkit';
import {
  TestCase,
  TestCaseCreate,
  TestCaseResult,
  TestCaseStepRemove,
  TestCaseStepUpdate,
  TestCaseUpdate,
} from './interfaces';

export const fetchTestCases = createAction<string>('FETCH_TEST_CASES');
export const fetchTestCase = createAction<string>('FETCH_TEST_CASE');
export const setTestCases = createAction<TestCase[]>('SET_TEST_CASES');
export const updateTestCase = createAction<TestCaseUpdate>('UPDATE_TEST_CASE');
export const createTestCase = createAction<TestCaseCreate>('CREATE_TEST_CASE');
export const addTestCase = createAction<TestCaseCreate>('ADD_TEST_CASE');

export const updateTestCaseStep = createAction<TestCaseStepUpdate>('UPDATE_TEST_CASE_STEP');
export const setTestCaseStep = createAction<TestCaseStepUpdate>('SET_TEST_CASE_STEP');
export const addTestCaseStep = createAction<TestCaseStepUpdate>('ADD_TEST_CASE_STEP');
export const createTestCaseStep = createAction<TestCaseStepUpdate>('CREATE_TEST_CASE_STEP');
export const removeTestCaseStep = createAction<TestCaseStepRemove>('REMOVE_TEST_CASE_STEP');

export const fetchTestCaseResults = createAction<TestCaseResult[]>('FETCH_TEST_CASE_RESULTS');
export const setTestCaseResults = createAction<TestCaseResult[]>('SET_TEST_CASE_RESULTS');
export const fetchTestCaseResult = createAction<string>('FETCH_TEST_CASE_RESULT');
