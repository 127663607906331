import React from 'react';
import { Link as BaseLink } from 'react-router-dom';
import { Link as BaseStyle } from '@mui/material';

const Link = React.forwardRef(({ url, path, children, component, ...rest }, forwardRef) => {
  component = component || BaseLink;

  if (url) {
    return <a href={url}>{children}</a>;
  }

  return (
    <BaseStyle to={path} component={component} ref={forwardRef} {...rest}>
      {children}
    </BaseStyle>
  );
});

export default Link;
