import React from 'react';
import ButtonPlus from '../../assets/icons/button_plus.svg';
import useModal from '../../hook/useModal';
import ModalSchemaCreate from '../../components/ModalSchemaCreate';

export default function SchemasList({ schemas, projectId, setCurrentSchema }) {

  if (!schemas) {
    return (
      <>Loading</>
    );
  }

  const [Modal, handleOpenModal] = useModal(ModalSchemaCreate, {projectId});

  return (
    <>
      <h4>
        Schemas <img
        src={ButtonPlus}
        onClick={handleOpenModal}
        style={{ cursor: 'pointer', width: '40px', position: 'relative', top: '18px' }}
        alt={'Add new schema'}
      />
      </h4>
      {schemas.map((schema, index) => {
        return <div key={index} onClick={() => setCurrentSchema(schema)} style={{ cursor: 'pointer' }}>
          {schema.name}
        </div>
      })}
      {Modal}
    </>
  );
}
