import { select } from 'redux-saga/effects';
import { getApiToken } from '../store/app/selectors';

function* defaultHeaders() {
    const state = yield select();

    return {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': getApiToken(state),
    };
}

export function handleResponse(response) {
    if (response.ok) {
        return response.json();
    }

    return response;
}

export function handleError(error) {
    console.log('handleError', error);
}

export {
    defaultHeaders,
}
