import { connect } from 'react-redux';
import Component from './PageTestCaseList';
import { getTestCases } from '../../store/testCase/selectors';
import { fetchTestCases } from '../../store/testCase/actions';

const mapStateToProps = (state) => {
  return {
    getTestCases: (projectId) => getTestCases(state, projectId),
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchTestCases: (projectId) => dispatch(fetchTestCases(projectId))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Component);
