import React, { Fragment, useState } from 'react';
import { Grid } from '@mui/material';
import ButtonPlus from '../../../assets/icons/button_plus.svg';

export default function EnvironmentVariablesForm({ environment, updateEnvironment }) {

  const [environmentState, setEnvironmentState] = useState(environment);

  const onGlobalVariableChange = (e) => {
    let tmp = JSON.parse(JSON.stringify(environmentState));
    tmp.globalVariables[e.target.dataset['index']][e.target.name] = e.target.value;
    setEnvironmentState(tmp);
  }

  const handleGlobalVariableChange = () => {
    updateEnvironment({
      environment: environmentState,
      successCallback: undefined,
    });
  }

  const handleAddGlobalVariable = () => {
    let tmp = JSON.parse(JSON.stringify(environmentState));
    tmp.globalVariables.push({
      name: '',
      value: '',
    });
    setEnvironmentState(tmp);
    updateEnvironment({
      environment: tmp,
      successCallback: undefined,
    });
  }

  const handleRemoveGlobalVariable = (e) => {
    let tmp = JSON.parse(JSON.stringify(environmentState));
    tmp.globalVariables.splice(e.target.dataset['index'], 1);
    setEnvironmentState(tmp);
    updateEnvironment({
      environment: tmp,
      successCallback: undefined,
    });
  }

  return (
    <>
      <img
        src={ButtonPlus}
        alt={'Add new step'}
        style={{ width: '24px', lineHeight: '12px', verticalAlign: 'middle', marginRight: '8px' }}
        className={'cursor'}
        onClick={(e) => handleAddGlobalVariable(e, environmentState)}
      />
      <span>Global variables:</span>
      {environmentState.globalVariables.map((variable, index) => {
        return <Fragment key={index}>
          <Grid container>
            <Grid item xs={'auto'} style={{  padding: '28px 12px 0 7px' }}>
                <span data-index={index} className={'cursor'} onClick={handleRemoveGlobalVariable}>
                  🗑
                </span>
            </Grid>
            <Grid item xs={5}>
              <input type={'text'} name={'name'} data-index={index} placeholder={'Name'} value={variable.name} onChange={onGlobalVariableChange} onBlur={handleGlobalVariableChange}/>
            </Grid>
            <Grid item xs={6}>
              <input type={'text'} name={'value'} data-index={index} placeholder={'value'} value={variable.value} onChange={onGlobalVariableChange} onBlur={handleGlobalVariableChange}/>
            </Grid>
          </Grid>
        </Fragment>;
      })}
    </>
  );
}
